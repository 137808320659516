:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}

.AboutUsMain {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 8%;
    margin-bottom: 8%;
}

.AboutUsStart {
    display: flex;
    width: 85%;
    justify-content: space-between;
}

.AboutUsContent {
    display: flex;
    width: 47%;
    box-shadow: none;
}

.Content1 {
    width: 95%;

}

.Content1 h5 {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: var(--bl) !important;
    cursor: pointer;
text-align: justify;
}

.Content1 h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 23px;
    margin-bottom: 20px;
    color: #585858 !important;
}

.Content1 button {
    margin-top: 20px;
    width: 23%;
    padding: 1.5% 0;
    font-size: 15px;
    font-weight: 400;
    background: var(--col);
    border-radius: 4px;
    text-transform: capitalize;
    cursor: pointer;
}

.Content1 button:hover {
    background: var(--hov);
    color: var(--wt);

}

.AboutUsImage {
    display: flex;
    flex-direction: column;
    width: 50%;

}

.AboutUsImage img {
    display: flex;
    object-fit: contain;
    width: 100%;
    border-radius: 1%;
}

.AboutUsImage h2 {
    display: none;
}

/* ----------------------media---------------------- */


@media(max-width:1248px) {
    .Content1 h5 {
        font-size: 14px;
    }

}

@media(max-width:1025px) {
    .AboutUsContent {
        width: 48%;
    }

    .AboutUsImage {
        width: 48%;
    }

    .Content1 h5 {
        font-size: 12px;
    }

}

@media(max-width:913px) {
    .Content1 h2 {
        font-weight: 600;
        font-size: 28px;
        line-height: 20px;
        margin-bottom: 4%;
    }

    .Content1 h5 {
        font-size: 14px;
        line-height: 20px;
    }

    .Content1 button {
        margin-top: 4%;
        width: 30%;

    }


}

@media(max-width:820px) {
    .AboutUsStart {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .AboutUsContent {
        display: flex;
        width: 100%;
        box-shadow: none;
        margin-bottom: 4%;
    }

    .Content1 {
        width: 100%;

    }

    .AboutUsImage {
        display: flex;
        width: 100%;
    }


    .Content1 h5 {
        font-size: 13px;
        width: 100%;
    }

    .Content1 h2 {
        width: 100%;
        font-size: 26px;
    }

    .Content1 button {
        width: 20%;
        font-size: 13px;

    }

}


@media(max-width:415px) {

    .Content1 {
        width: 100%;
    }


    .Content1 h5 {
        width: 100%;
        font-size: 12px;
    }

    .Content1 h2 {
        width: 50%;
        font-size: 20px;
    }

    .Content1 button {
        width: 30%;
        font-size: 12px;
        padding: 2.5% 0;
        margin-bottom: 5%;

    }

    .AboutUsStart {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .Content1 h2 {
        display: none;
    }
    .AboutUsImage h2 {
        display: inline-block;
        font-weight: 600;
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 4%;
    }
    .AboutUsImage img{
        margin-bottom: 4%;
    }
}