.BgImg {
    background: linear-gradient(123.13deg, rgba(0, 0, 0, 0.479) 75.08%,
            rgba(0, 0, 0, 0.008) 104.94%), url("https://res.cloudinary.com/dxluokypg/image/upload/v1681560761/shcbscbszkjckszbkjckszbckjzsbckuzsbjcbzsbcjzsbcjzsjcbjzsbcjzsbckzsc_vbxtun.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    width: 100%;
    height:40vh;

}
@media(max-width:540px){

    .BgImg h3 {
        display: flex;
        font-weight: 600;
        font-size: 25px;
        line-height:32px;
    }
    .BgImg h6 {
        display: flex;
        font-weight: 400;
        font-size: 15px;
        line-height:18px;
    }
  
}
@media(max-width:415px){

    .BgImg {
        height: 25vh;
        padding-top: 2vh;
    
    }
    .BgImg h3{
        width: 85%;
        font-weight: 600;
        font-size: 20px;
        line-height:30px;
    
    }
    .BgImg h6{
        width: 85%;
        font-weight: 400;
        font-size: 12px;
        text-align: start;
        line-height:18px;
    }
    
    }
  
