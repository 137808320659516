:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}

.MissionMain {
    display: flex;
    width: 100%;
    background: linear-gradient(123.13deg, rgba(0, 0, 0, 0.479) 25.08%, rgba(2, 2, 2, 0.529) 104.94%), url(https://res.cloudinary.com/dxluokypg/image/upload/v1680155791/mission_possible1_mzerbd.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 50vh;
    align-items: flex-end;
}

.MissionContent {
    width: 45%;
    display: flex;
    justify-content: space-around;
    background: var(--col);
    padding: 2%;
    position: relative;
    bottom: -20px;
    left: 8%;
    z-index: 2;
}

.MissionContent h2 {
    font-weight: 700;
    font-size: 50px;
    line-height: 64px;
    color: var(--wt);
}

.MissionContent h6 {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: var(--wt);
}

/* ------------media----------- */

@media(max-width:1150px) {
    .MissionContent {
        width: 50%;
    }

    .MissionContent h2 {
        font-size: 45px;
        line-height: 60px;
    }

    .MissionContent h6 {
        font-size: 13px;
        line-height: 25px;
    }
}

@media(max-width:1000px) {
    .MissionMain {
        display: flex;
        width: 100%;
        min-height: 40vh;

    }

    .MissionContent {
        width: 60%;
    }

    .MissionContent h2 {
        font-size: 42px;
        line-height: 55px;
    }

    .MissionContent h6 {
        font-size: 10px;
        line-height: 22px;
    }
}

@media(max-width:756px) {
    .MissionContent {
        width: 50%;
    }

    .MissionContent h2 {
        font-size: 35px;
        line-height: 50px;
    }

    .MissionContent h6 {
        font-size: 10px;
        line-height: 20px;
    }
}

@media(max-width:550px) {
    .MissionContent {
        width: 50%;
    }

    .MissionContent h2 {
        font-size: 30px;
        line-height: 40px;
    }

    .MissionContent h6 {
        font-size: 10px;
        line-height: 20px;
    }
}

@media(max-width:415px) {
    .MissionMain {
        display: flex;
        justify-content: flex-start !important;
    }

    .MissionContent {
        width: 85%;
        left: 0;
        display: flex;
        justify-content: space-around;
        text-align: center;
        margin-bottom: 5%;
    }

    .MissionContent h2 {
        font-size: 25px;
    }

    .MissionContent h6 {
        font-size: 15px;
    }
}