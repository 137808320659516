.companyDetails{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
}

.companyDetails_content{
    width: 85%;
}

.companyDetail{
    background-color: #F9F9F9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5%;
    height: 83px;
    padding-left: 3%;
    padding-right: 1%;
}

.detailName{
    color: #585858;
    font-weight: 500;
    font-size: 15px;
}

.detailLink{
    padding-right: 2%;
}
@media(max-width:1280px){
    .companyDetails h2{
       font-size: 28px;
       margin-bottom: 2%;
    }
 
}
@media(max-width:1024px){
    .companyDetails h2{
        font-size: 26px;
     }
    .companyDetail{
        margin-top: 0.5%;
        height: 70px;
        padding-left: 3%;
        padding-right: 1%;
    }
 
}
@media(max-width:912px){
    .companyDetails h2{
        font-size: 24px;
     }
    .companyDetail{
        margin-top: 0.5%;
        height: 65px;
        padding-left: 2%;
        padding-right: 1%;
    }
    .detailName{
        font-size: 12px;
    }
 
}
@media(max-width:768px){
    .companyDetails h2{
        text-align: center;
        font-size: 22px;
     }
    .companyDetail{
        margin-top: 1%;
        height: 60px;

    }
    .detailName{
        font-size: 12px;
    }
    .detailLinkicon{
        font-size: 25px;
    }
 
}
@media(max-width:540px){
    .companyDetails h2{
        font-size: 20px;
     }
    .companyDetail{
        margin-top: 1%;
        height: 50px;

    }
    .detailName{
        font-size: 10px;
    }
    .detailLinkicon{
        font-size: 20px;
    }
 
}
@media(max-width:415px){
    .companyDetails_content h2{
        text-align: start;
        padding-bottom: 2%;
        margin-top: 15%;
    }
 .companyDetails_content{
    margin-bottom: 10%;
 }
}