:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}

.JobDescInformationMain {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 3%;
}

.JobDescInformationStart {
    display: flex;
    width: 85%;
    justify-content: space-between;

}

.JobDescBoxFrist {
    width: 55%;
}

.JobDescInformationMiddle {
    width: 30%;
    /* height: 100vh; */
    display: flex;
    flex-direction: column;
}

.BoxessDown {
    width: 100%;
}
.JobContain {
    width: 85%;
    margin-top: 10%;
    margin-bottom: 10%;
}


.JobDescBoxSecond {
    display: flex;
    width: 100%;
    box-shadow: 0px 1px 5px 1px rgba(82, 80, 80, 0.607);
    justify-content: center;
    margin-bottom: 10%;

}

.JobDescBoxThird {
    display: flex;
    width: 100%;
}

/* ----------------------------- */
.JobDescBoxFrist label {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: var(--bl);
}
/* 
.JobDescBoxFrist ul {
    padding-top: 4%;
}

.JobDescBoxFrist ul li {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--bl);
    padding-bottom: 1%;
} */

.JobDescBoxFrist h4 {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: var(--bl);
    padding-top: 3%;
    padding-bottom: 3%;
    text-align: justify;

}

.JobDescBoxFrist .boxgap {
    margin-bottom: 5%;

}

.JobDescBoxSecond .JobContain {
    width: 80%;
}

.JobDescBoxSecond label {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: var(--bl);

}

.JobDescBoxSecond .Boxess {
    margin-bottom: 10%;
    margin-top: 10%;
}

.JobDescBoxSecond h3 {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #D4D4D4;

}

.JobDescBoxSecond h6 {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;

}

.JobDescBoxSecond a {
    color: var(--bl);

}

.JobDescBoxThird .BoxessDown {
    display: flex;
    width: 85%;
    flex-direction: column;
}

.JobDescBoxThird .SocialIcons {
    justify-content: space-between;
    display: flex;
    width: 70%;
}

.JobDescBoxThird .SocialIcons .icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.BoxessDown h6 {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    color: #001833;
    margin-bottom: 4%;
}

.DescDown {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 8%;
}

.DescBtnStart {
    display: flex;
    width: 10%;
}

.DescDown button {
    font-weight: 400;
    font-size: 14px;
    background: var(--col);
    border-radius: 8px;
    width: 100%;
    padding-top: 6%;
    padding-bottom: 6%;
}

.DescDown button:hover {
    background: var(--hov);
    color: var(--wt);

}

@media(max-width:1280px) {
    .JobDescBoxFrist {
        width: 60%;
    }

    .JobDescInformationMiddle {
        width: 35%;

    }

    .SocialIcons {
        display: flex;
        gap: 30px;
    }

    .DescDown {
        display: flex;
        width: 100%;
    }

    .DescBtnStart {
        display: flex;
        width: 15%;
    }

    .DescBtnStart button {
        padding: 3% 0;
        font-size: 12px;
    }
}

@media(max-width:912px) {
    .JobDescBoxFrist .boxgap h4{
        width: 100%;
    }
    .JobDescBoxFrist label {
        font-size: 18px;
    }

    .JobDescBoxFrist ul {
        padding-top: 2%;
    }

    .JobDescBoxFrist ul li {
        font-size: 12px;
    }
    .JobDescBoxFrist h4 {
        font-size: 12px;
        line-height: 20px;

    
    }
    .SocialIcons {
        display: flex;
        gap: 20px;
    }
}
@media(max-width:768px) {
    .JobDescInformationStart{
        display: flex;
        flex-direction: column;
    }
    .JobDescBoxFrist{
        width: 100%;
        
        
    }
    .JobDescInformationMiddle{
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .JobDescBoxSecond .Boxess {
        margin-bottom: 2%;
        margin-top: 2%;
    }
    .DescBtnStart {
        display: flex;
        width: 25%;
        margin-top: 50px;
    }
    .SocialIcons {
        display: flex;
        gap: 0 !important;
    }
    
    .DescBtnStart button {
        padding: 4% 0;
        font-size: 14px;
    }
    
   
}

@media(max-width:540px) {

    .JobDescInformationMiddle{
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .JobDescBoxThird{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .BoxessDown{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .BoxessDown h6{
        text-align: center;
    }

   .DescDown{
    margin-top: 0;
   }
   .DescBtnStart {
    display: flex;
    width: 30%;
}

.DescBtnStart button {
    padding: 4% 0;
    font-size: 12px;
}
     
}


/* -------------phone view---------- */
@media(max-width:415px){

    .JobDescBoxThird{
        display: none;
    }
    .DescBtnStart {
        display: flex;
        width: 30%;
    }
    .DescBtnStart button {
        padding: 5% 0;
        font-size: 12px;
    }
    .JobDescInformationStart{
        display: flex;
        flex-direction: column-reverse;
    }
    .JobContain .Boxess{
        padding-bottom: 2%;
    }
    .DescDown{
        margin-top: 5%;
    }
    .JobDescBoxSecond {
        box-shadow: 0px 1px 5px 1px rgba(204, 204, 204, 0.607);
    }
}

