:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}
.mainsec{
    display: flex;
    width: 100%;
    background-color: var(--col);
    height: 40vh;
    justify-content: center;
    align-items: center;
}


.innersec{
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 85%; 
}

.Bnr_heading{
    font-size: 30px;
    font-weight: 700;
    color:var(--wt);
    line-height: 36px;

}


.Bnr_para{
    font-size: 15px;
    font-weight: 400;
    color:var(--wt);
    line-height: 24px;


}

@media(max-width:1280px){
    .innersec .Bnr_heading {
        font-size: 28px;
    }
    
    .innersec .Bnr_para {
        font-size: 14px;
    }
}
@media(max-width:820px){
    .innersec .Bnr_heading {
        font-size: 25px;
        line-height: 30px;
    }
    .innersec .Bnr_para {
        font-size: 12px;
    }

}

@media(max-width:415px){
    .mainsec{
        height: 25vh;
        margin-bottom: 5%;
    }
    .innersec .Bnr_heading {
        font-size: 20px;
        line-height: 30px;
    }
    .innersec .Bnr_para {
        font-size: 12px;
        padding-bottom: 2%;
    }

}