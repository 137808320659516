:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}

.AbtMainSec {
    display: flex;
    width: 100%;
    height: 40vh;
    justify-content: center !important;
    align-items: center !important;
    background: url('https://res.cloudinary.com/dxluokypg/image/upload/v1681535472/AboutusBnrImg_owgvs8.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; 
    box-sizing: border-box;
}

.AbtInnerSec {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 85%; 
}


.AbtBnrHeading{
    font-size: 36px;
    font-weight: 500;
    color:var(--wt);
    line-height: 36px;



}


@media(max-width:768px) {
    .AbtBnrHeading{
        font-size: 30px;
    }
    .AbtMainSec {
        height: 30vh;
    }
    
}
@media(max-width:415px) {
    .AbtBnrHeading{
        font-size: 20px;
        font-weight: 600;
    }
    .AbtBnrHeading{
        display: flex;
        width: 100%;
        text-align: start;
    }
    .AbtMainSec {
        display: flex;
        width: 100%;
        height: 25vh;
        background: url('https://res.cloudinary.com/dxluokypg/image/upload/v1684401338/Group_48097235_fzfhly.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover; 
        box-sizing: border-box;
    }
}