:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}

.OurStoryMainSec {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.OurStoryInnerSec {
    display: flex;
    flex-direction: column;
    width: 85%;
}

.StoryBnrPara {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.StoryBnrPara h6 {
    font-weight: 400;
    font-size: 15px;
    font-family: 'Inter';
    line-height: 23px;
    color: var(--bl);
    padding-top: 2%;
    padding-bottom: 4%;
    text-align: justify;
}

.StoryBnrPara h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 23px;
    color: var(--bl);
}

.StoryImgSec {
    width: 100%;
    display: flex;
    position: relative;
    height: 476px;
    /* background: url('https://res.cloudinary.com/dxluokypg/image/upload/v1681548652/Team_nqitcx.png'); */
    background:linear-gradient(360deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5187324929971988) 45%, rgba(0,0,0,0.27503501400560226) 100%) , url('https://res.cloudinary.com/dtffniutw/image/upload/v1694841588/735ecf7dff3aa82016e1b9fc580d514f_qimbod.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
}
.StoryImgSec h5{
    text-align: justify;
}
.StoryBoxSecPara {
    display: flex;
    position: absolute;
    bottom: 0%;
    background-color: var(--col);
    width: 54%;
    padding: 1.5% 3% 1% 1%;
    border-top-right-radius: 5px;
}

.StoryImgSecHide {
    display: none;
    background-color: var(--col);
    width: 100%;
    padding: 3%;

}

.StoryImgSecHide h5 {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: var(--wt);
}

.StoryBoxSecPara h5 {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: var(--wt);
    text-align: justify;
}

.CounterSection {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;

}

.countersecBox1 {
    display: flex;
    width: 55%;
}

.countersecBox2 {
    display: flex;
    width: 40%;
    justify-content: space-between;
}

.countersecBoxPara {
    font-size: 15px;
    font-weight: 400;
    color: var(--bl);
    line-height: 23px;
    text-align: justify;
}

.count0 {
    width: 32%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.countertxt {
    font-weight: 860;
    font-size: 60px;
    line-height: 22px;
    color: var(--col);
    padding: 15% 0%;
}

.count0 h4 {
    font-weight: 590;
    font-size: 30px;
    line-height: 22px;
    color: var(--bl);
    padding-bottom: 5%
}

/* ---------------------------------------- */
@media(max-width:1250px) {
    .StoryBnrPara h6 {
        font-size: 12px;
    }

    .StoryBnrPara h2 {
        text-align: center;
        font-size: 25px;
    }

    .StoryBoxSecPara h5 {
        font-size: 12px;
    }

    .countersecBoxPara {
        font-size: 12px;
    }

    .countertxt {
        font-size: 50px;
    }

    .count0 h4 {
        font-size: 25px;
    }

}

@media(max-width:1050px) {
    .countertxt {
        font-size: 40px;
    }

    .count0 h4 {
        font-size: 20px;
    }

}

@media(max-width:825px) {
    .CounterSection {
        display: flex;
        flex-direction: column;
    }

    .countersecBox1 {
        width: 100%;

    }

    .StoryBoxSecPara {
        width: 100%;
    }

    .StoryBoxSecPara h5 {
        width: 100%;
    }

    .StoryBnrPara {
        width: 100%;
    }

    .StoryBnrPara h2 {
        width: 100%;
    }

    .StoryBnrPara h6 {
        width: 100%;
    }

    .countersecBoxPara {
        padding-bottom: 4%;
    }

    .countersecBox2 {
        width: 100%;
    }

    .StoryBoxSecPara {
        width: 100%;
    }

    .StoryBoxSecPara h5 {
        font-size: 11px;
        line-height: 17px;

    }

    .StoryBoxSecPara {
        width: 100%;
    }

    .StoryImgSec {
        height: 60vh;
    }

    .count0 h4 {
        width: 100%;
        text-align: center;
    }
}

@media(max-width:768px) {
    .StoryImgSec {
        height: 50vh;
    }
}

@media(max-width:540px) {
    .StoryImgSec {
        height: 40vh;
    }

    .StoryBoxSecPara h5 {
        font-size: 10px;
        line-height: 18px;
    }

    .countertxt {
        font-size: 35px;
    }

    .count0 h4 {
        width: 100%;
        text-align: center;
        font-size: 15px;
    }
}

@media(max-width:415px) {
    .StoryBnrPara h2 {
        text-align: start;
        font-size: 20px;
        padding-bottom: 2%;
        padding-top: 5%;
    }
    .StoryBnrPara h6{
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
    .StoryImgSec {
        height: 30vh;
    }

    .StoryBoxSecPara h5 {
        font-size: 6px;
        line-height: 15px;
    }

    .countertxt {
        font-size: 25px;
    }

    .count0 h4 {
        width: 100%;
        text-align: center;
        font-size: 12px;
    }

    .CounterSection {
        display: flex;
        flex-direction: column-reverse;
    }

    .StoryImgSecHide {
        display: block;
        border-radius: 0 0 4px 4px;
    }

    .StoryImgSecHide h5 {
        width: 100%;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--wt);
    }

    .StoryBoxSecPara {
        display: none;
    }

    .StoryImgSec {
        border-radius: 4px 4px 0 0;
    }
    .count0 {
        text-align: start;
    }
    .count0 h4 {
        width: 100%;
        text-align: start;
        color: #16469D;
        font-weight: 400;
        padding-bottom: 10%;

    }
    .countertxt {
        padding: 10% 0%;
    }
    .countersecBoxPara {
        font-size: 12px;
        font-weight: 400;
        color: var(--bl);
        line-height: 18px;
    }
}