:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}

.bgimg {
    display: flex;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 2% 0 0 10% !important;
}

.bg2 {
    height: 85vh;
    background-color: #F6FAFF !important;
    opacity: 0.8;
    display: flex;
}

.tabtxt {

    font-size: 30px;
    line-height: 23px;
    color: rgba(255, 255, 255, 0.5);
    /* padding: 18% 0 18% 15%; */
    text-transform: none;
    letter-spacing: 6%;


}

.Boxess0 h1 {
    width: 100%;
}

.tabdesHeading {
    font-size: 30px;
    line-height: 23px;
    color: var(--wt);
    padding-top: 10%;
    text-transform: none;
    letter-spacing: 6%;
    fill: solid #F6FAFF;
    display: flex;
}

.tabdesc {
    font-size: 15px;
    line-height: 23px;
    color: var(--wt);
    text-transform: none;
    letter-spacing: 6%;
    fill: solid #F6FAFF;
    display: flex;
    color: var(--bl);
    width: 70%;
    margin-top: 5%;

}

.tabdescBtn {
    width: 22%;
    padding: 2% 0;
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
    color: var(--wt);
    text-transform: none;
    letter-spacing: 6%;
    background-color: var(--col);
    color: #ffffff;
    margin-top: 5%;
}

.tabdescBtn:hover {
    background-color: var(--hov);
    color: var(--wt);
}

.heading {
    font-size: 30px;
    font-weight: 700;
    line-height: 23px;
    color: var(--wt);
    margin: 5% 0 !important;

}

.MuiTabs-indicator {
    position: relative !important;
    width: 5px !important;
    background-color: #F6FAFF !important;
}


.BusinesssBoxes {
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.BusiBoxStart {
    display: flex;
    width: 85%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Boxess0 {
    border-bottom: 1px solid rgba(61, 61, 61, 0.275);
}

.Boxess0 p {
    text-align: justify;
}

.bgimgContent p {
    text-align: justify;
}

.Boxess0 h4 {
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
    color: var(--bl);
    padding-bottom: 2%;
    padding-top: 2%;
}

.BusiBoxStart h6 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 2%;
    color: #585858;
    display: flex;
    width: 100%;
    text-align: start !important;
    justify-content: flex-start;
}

.Boxess0 button {
    margin-bottom: 4%;
}

.Boxess0 h3 {
    color: var(--bl);
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
}

.tabdescBtn2 {
    margin-top: 2%;
    background-color: var(--col);
}

.tabdescBtn2:hover {
    margin-top: 2%;
    background-color: var(--hov);
}

/* 
@media(max-width:1226px) {
    .secondpart {
        padding: 5%;
    }

}

@media(max-width:900px) {
    .bgimg {
        display: none;
    }

    .bgimgContent {

        display: none;
    }

    .BusinesssBoxes {
        display: flex;

    }

    .Boxess0 {
        max-width: 100%;
        min-width: 100%;

    }

    .Boxess0 h1 {
        width: 100%;
        overflow-wrap: anywhere;
    }
}

@media(max-width:820px) {
    .bgimg {
        display: none;
    }

    .bgimgContent {
        display: none;
    }

    .Boxess0 h1 {
        font-size: 25px;
        font-weight: 600;
        margin: 2% 0;
    }
}




@media(max-width:600px) {
    .tabtxt {

        padding-top: 30%;
    }

    .BusinessIMg {
        height: 45vh !important;
        max-width: 100% !important;

    }


    .Boxess0 h1 {
        font-weight: 500;
        font-size: 28px;
        color: #585858;
    }

    .tabdesHeading {
        font-size: 25px;
        padding-top: 5%;
        padding-left: 5%;
    }

    .tabdesc {
        padding-top: 1%;
        padding-top: 0px;
    }

    .tabdescBtn {
        margin-bottom: 8%;
    }

}

@media(max-width:540px) {

    .Boxess0 p {
        font-weight: 400;
        line-height: 20px;
        font-size: 13px;
    }

    .BusinessIMg {
        height: 40vh !important;

    }

}

@media(max-width:768px) {
    .tabtxt {

        padding-top: 30%;

    }

    .Boxess0 h1 {
        font-weight: 500;
        font-size: 26px;
    }

    .tabdesHeading {
        font-size: 25px;
        padding-top: 5%;
        padding-left: 5%;

    }

    .tabdesc {
        padding-top: 1%;
        padding-top: 0px;
    }

    .tabdescBtn {
        margin-bottom: 8%;
        margin-left: 5%;
    }
}


@media(max-width:1024px) {
    .tabtxt {

        padding-top: 30%;

    }

    .tabdesHeading {
        font-size: 25px;
        padding-top: 5%;
        padding-left: 5%;

    }

    .tabdesc {
        padding-top: 1%;
        padding-top: 0px;
    }

    .tabdescBtn {
        margin-bottom: 8%;
        margin-left: 5%;
    }
}

@media(max-width:415px) {
    .bgimg {
        display: none;
    }

    .Boxess0 {
        border-bottom: none;
    }

    .Boxess0 h1 {
        font-size: 20px;
        padding-top: 5%;
        font-weight: 700;
    }

    .Boxess0 p {
        font-size: 12px;

    }

    .Boxess0 button {
        width: 30%;
        font-size: 12px;
        margin-bottom: 10%;
        margin-top: 5%;
        padding: 2.5% 0;

    }

    .Boxess0 {
        margin-bottom: 5%;
    }

    .BusinessIMg {
        height: 35vh !important;
        margin-top: 5%;

    }

}

@media(max-width:360px) {
    .BusinessIMg {
        height: 30vh !important;

    }
} */


.BusinesMobileStart{
    width: 100%;
    display: flex;
    flex-direction: column;   
    justify-content:center;
    align-items: center;
    gap: 30px;
}
.BusinesStart{
    width: 85%;
    display: flex;
    flex-direction: column;   
    gap: 5px;
}
.BusinesStart >div {
    width: 100%;
    height: 40vh;
    display: flex;
}
.BusinesStart >div img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: flex;
}

.BusinesMobileStart{
    display: none;
}
.BusinesMobileStart h6{
   font-size: 25px;
   font-weight: 800px;
   color: #585858;
}

@media screen and (max-width:768px){
    .BusinesMobileHide{
        display: none !important;
    }

    .BusinesMobileStart{
        display: flex;
    }
    .BusinesStart>div{
        height: 30vh !important;
        object-fit: contain;
        border-radius: 8px;
    }
    .BusinesMobileStart h6{
        font-size: 20px;
        font-weight: 600px;
        color: #000;
        font-family: Inter,sans-serif
     }
    .BusinesStart h1{
        font-style: 15px;
        font-weight: 500;
     }
     
    .BusinesStart p{
        text-align: justify !important;
        font-size: 12px;
}     
}