:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}

.lineclamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }
.newsContent  *{
   font-weight: 300 !important;
   font-size: 15px;
  }
  
.NewsMain {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4%;
    margin-bottom: 8%;
}

.NewsStart {
    display: flex;
    width: 85%;
}

.NewsStart a {
    display: flex;
    width: 35%;
}

.ContentInfo {
    width: 38%;
    display: flex;
    justify-content: center;
    margin-top: 3%;

}

.ContentImg {
    width: 22%;
    background: linear-gradient(123.13deg, rgba(0, 0, 0, 0.479) 85.08%, rgba(0, 0, 0, 0.008) 104.94%), url(https://res.cloudinary.com/dxluokypg/image/upload/v1680167956/newsImg_n9ma69.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    border-radius: 5px;
}

.ContentBG {
    display: flex;
    flex-direction: column;
    width: 40%;
}


.ContentInfoStart {
    width: 85%;
    display: flex;
    flex-direction: column;
}

.ContentInfoStart a {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ContentBG img {
    width: 100%;
}


.ContentBG h6 {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.06em;
    text-transform: capitalize;
    color: var(--bl);
    padding-bottom: 2%;
    padding-top: 3%;
}

.ContentBG h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -1px;
    color: var(--bl);
    padding-bottom: 3%;
}

.ContentBG h5 {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: var(--bl);
}

.ContentBG h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 23px;
    color: var(--bl);
    padding-bottom: 5%;

}

/* ------------------- */
.ContentInfo h6 {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.06em;
    text-transform: capitalize;
    color: var(--bl);
    padding-bottom: 1%;

}

.ContentInfo h4 {
    font-weight: 590;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -1px;
    color: var(--bl);
    padding-bottom: 2%;
}

.ContentInfo h5 {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: var(--bl);
    /* padding-top: 1%;
    padding-bottom: 6%; */
}

.ContentInfoStart .Info {
    border-bottom: 1px solid rgba(217, 217, 217, 1);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
}

.ImgBox {
    width: 80%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ImgBox h5 {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: var(--wt);
    padding-bottom: 5%;
}

.ImgBox button {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    background-color: var(--col);
    color: var(--wt);
    text-transform: capitalize;
    margin-top: 10%;
    padding: 7% 0;
    display: flex;
    width: 100%;

}

.ImgBox button:hover {
    background-color: var(--hov);
}

/* -----------media-------------- */

@media(max-width:1200px) {
    .NewsStart {
        display: flex;
        width: 95%;
    }

    .ContentBG h6 {
        font-size: 13px;

    }

    .ContentBG h4 {
        font-size: 18px;
    }

    .ContentBG h5 {
        font-size: 13px;

    }

    .ContentInfo h6 {
        font-size: 13px;
    }

    .ContentInfo h4 {
        font-size: 18px;
    }

    .ContentInfo h5 {
        font-size: 13px;

    }

    .ImgBox button {
        font-size: 13px;

    }

    .ImgBox h5 {
        font-size: 15px;

    }

}

@media(max-width:900px) {
    .NewsStart {
        display: flex;
        transition: 0.6s ease-in-out;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .NewsStart a {
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ContentBG {
        width: 80%;
    }

    .ContentInfo {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 5%;
    }

    .ContentInfoStart {
        width: 80%;
    }

    .ContentImg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
        height: 100vh;
    }
}

@media(max-width:821px) {
    .ContentBG {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .ContentBG img {
        display: flex;
        flex-direction: column;
        width: 80%;
    }

    .ContentBG h3,
    h4,
    h5,
    h6 {
        width: 80%;
    }

    .ContentBG h3 {
        font-size: 25px;
        font-weight: 700;

    }

    .ContentInfoStart {
        margin-top: 2%;
    }

    .Info h4 {
        display: flex;
        width: 100%;
    }

    .Info h5 {
        display: flex;
        width: 100%;
    }

    .Info h6 {
        display: flex;
        width: 100%;
    }

    .ContentImg {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;
        height: 50vh;
    }

    .ImgBox button {
        font-size: 15px;
        display: flex;
        width: 70%;
        padding: 5% 0;

    }
}

@media(max-width:500px) {
    .ContentImg {
        width: 80%;
    }

    .ImgBox h5 {
        font-size: 18px;
    }

    .ContentInfo h6 {
        font-weight: 400;
        font-size: 13px;
    }

    .ContentInfo h4 {
        font-weight: 700;
        font-size: 17px;
    }

    .ContentInfo h5 {
        font-weight: 400;
        font-size: 13px;
    }

    .ContentBG h6 {
        font-weight: 400;
        font-size: 13px;
    }

    .ContentBG h4 {
        font-weight: 700;
        font-size: 17px;
    }

    .ContentBG h5 {
        font-weight: 400;
        font-size: 13px;
    }
}

@media(max-width:415px) {
    .ContentImg {
        width: 85%;
        display: none;
    }
    
    .NewsStart{
        width: 100% !important;
    }
    .ContentBG{
        width: 100% !important;
    }
    .ContentBG img{
        width:85% !important;
    }
    .ContentImg img {
        width: 85%;
        height: 80%;
    }

    .ImgBox h5 {
        font-size: 15px;
    }

    .ImgBox button {
        font-size: 15px;
    }

    .ContentInfo h6 {
        font-weight: 400;
        font-size: 12px;
    }

    .ContentInfo h4 {
        font-weight: 700;
        font-size: 15px;
    }

    .ContentInfo h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
    }


    .ContentBG h3 {
        font-weight: 700;
        font-size: 20px;
        padding-top: 5%;
        width: 100%;
        padding-left: 8%;
    }

    .ContentBG h4 {
        font-weight: 700;
        font-size: 15px;
    }

    .ContentBG h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
    }

    .ContentBG h6 {
        font-weight: 400;
        font-size: 12px;
    }
    .ContentBG 
    h4,
    h5,
    h6 {
        width: 85%;
    }

    .ContentInfoStart{
        width: 85%;

    }
}

.placeholderNewsParent
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.placeholderNewsDiv
{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow:1 ;
}

.placeholderNewsHead, .placeholderNewssubHead
{
    width: 100%;
    height: 25%;
    background-color: rgba(128, 128, 128, 0.121);
}
.placeholderNewsPara
{
    width: 100%;
   
    background-color: rgba(128, 128, 128, 0.121);
    height: 50%;
}
