:root {
    --col: #16469D;
    ;
}

.highlight_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}

.highlight_content {
    width: 85%;
    max-width: 1920px;
     
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
}

.highlight_boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.highlight_box {

    border: none;
    border-radius: 4px;
    width: 32%;
    height: 210px;
    margin-top: 2%;
    background-color: #b6b6b6;
}

.highlight_box img {
    width: 100%;
    height: 100%;
    position: relative;


}

.highlight_box img > div {
    position: absolute;
    top: 80px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 12px;
    gap: 5px;
    background-color: aqua;

}

/* .highlight_box > div {
    display: flex;
    flex-direction: column;
     justify-self: last baseline;
     align-self:flex-end;
    text-align: start;
    width: 100%;
    height: 100%;
    padding: 12px;
    gap: 5px;
    background-color: aqua;

} */

.highlight_heading {
    color: rgba(88, 88, 88, 1);
    font-weight: 600;
    font-size: 20px;

}

.highlight_desc {
    color: #585858;
    font-weight: 400;
    font-size: 15px;
    text-align: justify;

}

.invest_opp {
    width: 85%;
    display: flex;
    align-items: center;
    margin-bottom: 5%;
}

.invest_title {
    font-size: 20px;
    font-weight: 500;
    color: white;
    background-color: var(--col);
    display: flex;
    align-items: center;
    padding-left: 5%;
    height: 83px;
    width: 80%;
    border-radius: 4px 0px 0px 4px;
}

.invest_button {
    font-size: 20px;
    background-color: #F6FAFF;
    display: flex;
    align-items: center;
    width: 20%;
    padding-left: 5%;
    height: 83px;
    color: #16469D;
    border-radius: 0px 4px 4px 0px;
}

@media(max-width: 1300px) {
    .highlight_boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .highlight_box {
        width: 40%;

    }

}

@media(max-width: 1280px) {
    .highlight_box {
        width: 42%;

    }

    .highlight_heading {
        font-size: 18px;
    }

    .highlight_desc {
        font-size: 14px;
    }

    .highlight_content h1 {
        font-size: 28px;
        margin-bottom: 2%;

    }

    .invest_button {
        width: 20%;
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .invest_button h6 {
        font-size: 20px;
    }
}

@media(max-width: 1024px) {

    .highlight_heading {
        font-size: 16px;
    }

    .highlight_desc {
        font-size: 12px;
    }

    .highlight_content h1 {
        font-size: 25px;
    }

    .invest_title {
        font-size: 20px;
        padding-left: 5%;
        height: 60px;
        width: 80%;
    }

    .invest_button {
        width: 20%;
        height: 60px;
    }

    .invest_button h6 {
        font-size: 20px;
    }
}

@media(max-width: 912px) {
    .invest_title {
        font-size: 20px;
        padding-left: 4%;
        height: 50px;
        width: 70%;
    }

    .invest_button {
        width: 30%;
        height: 50px;
    }

    .invest_button h6 {
        font-size: 18px;
    }
}

@media(max-width: 820px) {
    .highlight_box {
        width: 45%;
    }

    .highlight_heading {
        font-size: 16px;
    }

    .highlight_desc {
        font-size: 12px;
    }

    .highlight_content h1 {
        font-size: 22px;
    }

    .invest_title {
        font-size: 18px;
        padding-left: 4%;
        height: 50px;
    }

    .invest_button {
        height: 50px;
    }

    .invest_button h6 {
        font-size: 18px;
        display: flex;
        justify-content: center;
    }
}

@media(max-width: 768px) {
    .highlight_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
    }

    .highlight_heading {
        font-size: 15px;
    }

    .highlight_desc {
        font-size: 12px;
    }

    .highlight_content h1 {
        text-align: center;
        font-size: 20px;

    }

    .invest_title {
        font-size: 15px;
        padding-left: 4%;
        height: 40px;
        width: 70%
    }

    .invest_button {
        height: 40px;
        width: 30%;
        padding: 0 !important;

    }

    .invest_button h6 {
        font-size: 12px;

    }
}

@media(max-width:550px) {
    .highlight_box {
        width: 90%;
        height: 190px;

    }

    .invest_title {
        font-size: 15px;
        padding-left: 4%;
        height: 35px;
        width: 70%
    }

    .invest_button {
        height: 35px;
        width: 30%;
        padding: 0 !important;

    }

    .invest_button h6 {
        font-size: 12px;

    }
}

@media(max-width:456px) {
    .invest_title {
        font-size: 12px;
        padding-left: 2%;
        height: 30px;

    }

    .invest_button {
        height: 30px;
        padding: 0 !important;

    }

    .invest_button h6 {
        font-size: 10px;

    }
}

@media(max-width:415px) {
    .highlight_content h1 {
        display: none;
    }

    .highlight_box {
        width: 100%;
        height: 150px;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 2%;
        border: none;
    }

    .invest_title {
        width: 60%;
        height: 40px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .invest_button {
        width: 40%;
        height: 40px;
        padding: 0 !important;

    }

    .invest_button h6 {
        font-size: 12px;

    }

    .invest_opp {
        margin-top: 10%;
    }
}


.highlight_boxes2
{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    gap: 20px;
    /* padding: 10px; */
    flex-wrap: wrap;
    
   
}

.highlightbox
{
    display: flex;
    height: 30vh;
    width: 30%;
    /* flex: 1 0 30%; */
    min-width: 300px;
    /* width: 100%; */
    padding: 10px;
    padding-bottom: 25px;
    background-size: cover;
    border-radius: 4px;
    background-repeat: no-repeat;
    

}

.highlightbox > div
{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 5px;

    

}
.highlightbox > div > h3
{
    font-size: 18px;
    color: white;
}
.highlightbox > div > p
{
    font-size: 14px;
    color: white;
}

@media screen and (max-width: 786px) {
    .highlightbox
{ 
    height: 25vh;
}

.highlightbox > div > h3
{
    font-size: 15px;
    color: white;
}
.highlightbox > div > p
{
    font-size: 12px;
    color: white;
}
}