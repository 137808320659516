.BgImg {
  background: linear-gradient(123.13deg, rgba(0, 0, 0, 0.479) 75.08%,
      rgba(0, 0, 0, 0.008) 104.94%), url(../../../assets/images/OurBusinessImg.svg);
  width: 100%;
  height: 40vh;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.TextInput12345 {
  display: flex;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  resize: none;
  text-indent: 10px;
  padding: 1% 0;
  border-radius: 5px;
}

.TextInput12345:focus {
  border: 2px solid #16469D !important;
}

@media(max-width:912px) {
  .BgImg {
    height: 35vh;
  }

  .BgImg h3 {
    font-size: 30px;
  }

}

@media(max-width:540px) {
  .BgImg {
    height: 30vh;
  }

  .BgImg h3 {
    font-size: 25px;
  }

}
@media(max-width:415px) {
  .BgImg {
    height: 25vh;

  }
  .BgImg h3 {
    font-size: 20px;
    width: 85%;
    text-align: start;
  }
}