:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}


.MissionInfoMain {
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #F6FAFF;
    opacity: 0.8;
}

.MissionInfoStart {
    display: flex;
    width: 85%;
    justify-content: space-between;
    margin-bottom: 2%;
}

.Content {
    width: 48%;
    padding-left: 1%;
    margin-top: 5%;

}

.Content h5 {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    color:var(--col);
    padding-bottom: 5%;
}

.Content h6 {
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    padding-bottom: 5%;
    color: var(--bl);
text-align: justify;


}

.Content h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    padding-bottom: 5%;
    letter-spacing: -1px;

}
/* ------------media---------------- */

@media(max-width:990px) {
  
    .Content h5 {
        font-weight: 590;
        font-size: 13px;
    }
    
    .Content h6 {
        font-weight: 400;
        font-size: 13px;
    }
    .Content h2 {
        font-weight: 700;
        font-size: 22px;    
    }
}
@media(max-width:678px) {
  
    .MissionInfoStart{
        flex-direction: column;
    }
    .Content {
        width: 100%;
        padding-top: 5%;
    }
    .Content h5 {
        font-weight: 550;
        font-size: 12px;
    }
    
    .Content h6 {
        font-weight: 350;
        font-size: 12px;
    }
    .Content h2 {
        font-weight: 650;
        font-size: 20px;    
    }
}
@media(max-width:540px) {
  
    .Content {
        width: 100%;
        padding-top: 5%;
    }


}
@media(max-width:415px) {
  
    .Content h5{
       font-size: 15px;
       font-weight: 600;
       padding-bottom: 2% !important;
    }
    .Content h6{
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }


}
