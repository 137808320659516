* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.AccordionMainSection {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.AccordionMain {
    display: flex;
    flex-direction: column;
    width: 77%;
    padding-bottom: 10%;
}

.AccordionMain h5 {
    font-size: 20px;
    font-weight: 700;
    color: #585858;
    line-height: 23px;
    padding-bottom: 2%;
}

.AccordionSection {
    width: 10%;
    font-size: 20px;
    font-weight: 500;
}

.AccordionAddress {
    width: 40%;
}

.AccordionContact {
    display: flex;
    width: 30%;
    padding: 2% 0;
    gap: 5%;
}

.MuiAccordionSummary-expandIcon {
    margin: 0;
}


@media(max-width:912px) {
    .AccordionMain h5 {
        font-size: 18px;
    }

    .AccordionSection {
        font-size: 18px;
    }

    .AccordionAddress {
        font-size: 15px;
    }

    .AccordionContact {
        font-size: 15px;

    }

}

@media(max-width:820px) {
    .AccordionMain h5 {
        font-size: 15px;
    }

    .AccordionSection {
        font-size: 15px;
    }

    .AccordionAddress {
        font-size: 12px;
    }

    .AccordionContact {
        font-size: 12px;

    }

}
@media(max-width:415px) {
    .AccordionAddress {
        width: 80%;
        padding-bottom: 2%;
    }

}