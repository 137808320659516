.InvSlideMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: sticky;
    scroll-behavior: smooth;
}

.InvSlideStart {
    display: flex;
    width: 85%;
}

.InvSlideStart span {
    border-radius: 10px;
}


.listStart {
    display: flex;
    width: 100%;


}

.list {
    display: flex;
    width: 16.50%;
    padding: 2% 0;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
    line-height: 34px;
    color: #323232;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 5px solid #F9F9F9;
}

.listBar {
    display: flex;
    width: 100%;
}

.listButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 5px solid #F9F9F9;
}

.listButton button {
    width: 55%;
    text-transform: capitalize;
    padding: 3% 0;
    background-color: #16469d;
}


/* --------------------------------------------- */


.ModalInput {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

.ModalInput form {
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

.ModalInput button {
    margin: 3% 0;
    width: 30%;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 24.012px;
    border-radius: 4px;
    background: #16469d;
    padding: 2.5% 0;
    text-transform: capitalize;
}


.FaqsModal h1 {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    margin: 5% 0 10% 0;
}

.FaqsModal h1:before,
.FaqsModal h1:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
}

.FaqsModal h1:before {
    margin-right: 10px;
}

.FaqsModal h1:after {
    margin-left: 10px;
}

.listButtonHide {
    display: none;
}


/* ------------------------------------------------------------------------------------------------------------- */

@media(max-width:820px) {
    .list {

        font-size: 15px;

    }

    .listButton button {
        font-size: 15px;
    }
}

@media(max-width:768px) {
    .list {
        width: 18.50%;
        font-size: 14px;

    }

    .listButton button {
        width: 80%;
        font-size: 14px;
    }
}

@media(max-width:600px) {
    .InvSlideStart {
        display: flex;
        width: 95%;
    }

    .list {
        font-size: 12px;
        padding: 1% 0 !important;
        line-height: 30px !important;

    }

    .listButton button {
        width: 100%;
        font-size: 12px;
    }

}

@media(max-width:490px) {
    .listButton {
        display: none;
    }

    .listButtonHide {
        display: block;
    }

    .InvSlideStart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .list {
        width: 25%;
        min-width: 40px !important;
        justify-content: center;
        align-items: center;


    }

    .listButtonHide button {
        margin: 5% 0;
        font-size: 12px;
    }

    .InvSlideStart {
        display: flex;
        width: 90%;
    }

    .listStart {
        display: flex;
        width: 100%;


    }


}


/* --------------------------------modal responsive------------------------------------ */


@media(max-width:500px) {

    .FaqsModal {
        width: 350px;
        height: 500px;
    }

    .FaqsModal h5 {
        font-size: 16px;
    }

    .FaqsModal h6 {
        font-size: 10px;
        line-height: 15px;
    }
    .ModalInput{
        display: flex;
        align-items: center !important;
    }
    .ModalInput input{
        width: 100%;
        padding: 10px 10px;
    }
    .ModalInput label{
       font-size: 10px;
       padding-bottom: 10px;
    }
    .ModalInput button {
        width: 40%;
        font-size: 12px;
        line-height: 22px;
        padding: 2.5% 0;
    }
    .FaqsModalButton a button{
       font-size: 10px;
       
    }
}
@media(max-width:415px){
    .listButtonHide{
        display: none !important;
    }
    .listButton{
        display: none !important;
    }
    .InvSlideMain {
        display: none;
    }
}
@media(max-width:360px) {

    .FaqsModal {
        width: 320px;
        height: 450px;
    }

    .FaqsModal h5 {
        font-size: 15px;
    }

    .FaqsModal h6 {
        width: 100%;
        font-size: 9px;
        line-height: 12px;
    }
    .ModalInput{
        display: flex;
        align-items: center !important;
    }
    .ModalInput input{
        width: 100%;
        padding: 8px 8px;
    }
    .ModalInput label{
       font-size: 10px;
       padding-bottom: 10px;
    }
    .ModalInput button {
        width: 45%;
        font-size: 10px;
        line-height: 20px;
        padding: 2.5%;
    }
    .FaqsModalButton a button{
       font-size: 8px;
       
    }
    .FaqsModalButton a button .icon{
       font-size: 18px;
       
    }
  
}