.linkweb:hover{
  color: #16469d !important;
  cursor: pointer;
  text-decoration: none !important;
  list-style: none;
}
.InvestContentMain {
    scroll-behavior: smooth;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;


}

.InvestContentStart {
    display: flex;
    width: 85%;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}


.InvestContent2 {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
    margin-top: 5%;
}

.InvestContent2 .InvestContentBox1 {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.InvestContent2 .InvestContentBox1 h5 {
    width: 100%;
    font-weight: 600;
    font-size: 30px;
    line-height: 26px;
    color: #585858;
    padding: 2% 0;

}

.InvestContent2 .InvestContentBox1 h6 {
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    line-height: 28px;
    color: #585858;
    padding: 2% 0;

}

.InvestContent2 .InvestContentBox1 h4 {
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #585858;
    padding: 2% 0;

}


.InvestContent2 .InvestContentBox1 ul {
    font-weight: 400;
    font-size: 15px;
    line-height: 34px;
    color: #585858;
    padding: 2% 0;

}

.InvestContent2 .InvestContentBox2 {
    display: flex;
    flex-direction: column;
    width: 35%;
    background: #F6FAFF;
    ;
}

.InvestContentOverview {
    padding: 5%;
}

.InvestContviewBox {
    display: flex;
    justify-content: space-between;
    padding: 5% 0;
    border-bottom: 1px solid black;
}

.InvestContentOverview h5 {
    font-weight: 400;
    font-size: 21px;
    line-height: 26px;
    color: #333333
}

.InvestContentOverview h6 {
    font-weight: 400;
    font-size: 13px;
    line-height: 23px;
    color: #333333;
}

.InvestContentOverview h4 {
    font-weight: 700;
    font-size: 13px;
    line-height: 23px;
    color: #333333;
}


/* ------------------------------------- */

.BusinessStart {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 5%;
}
.BusinessStart h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 26px;
    color: #585858;
    padding-bottom: 2%;
}

.BusinessStart h6 {
    width: 65%;
    font-weight: 400;
    font-size: 15px;
    line-height: 34px;
    color: #585858;
    margin-bottom: 2%;
}


.invest_opp_content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.investCont {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2%;
    background: #F6FAFF;
    border-radius: 4px;
    margin:  3% 0;
}

.investCont h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #4F5766;
}

.investCont button {
    margin: 1% 0;
    background-color:#16469d;
}

/* --------------------------------------- */
.ModalInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.ModalInput form{
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}


.ModalInput input {
    width: 100%;
    padding: 2% 0;
    border-radius: 5px;
    text-indent: 10px;
    margin: 3% 0;
    border: 1px solid gray;
}
.ModalInput input:hover {
    border-color: #16469d !important;
}



.ModalInput button {
    margin-top: 3%;
    margin-bottom: 2%;
    width: 30%;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 24.012px;
    border-radius: 4px;
    background: #16469d;
    padding: 2.5% 0;
    text-transform: capitalize;
}

.FaqsModal h1 {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    margin: 3% 0;
}
.FaqsModalButton .icon{
    text-decoration: none;
}

.FaqsModal h1:before,
.FaqsModal h1:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
}

.FaqsModal h1:before {
    margin-right: 10px;
}

.FaqsModal h1:after {
    margin-left: 10px;
}

.investMAINCARD{
    width: 300px;
    height: 200px;
}
@media(max-width:1500px){
.investMAINCARD{
    width: 280px;
    height: 190px;

}

}
@media(max-width:1400px){
.investMAINCARD{
    width: 250px;
    height: 180px;

}

}
@media(max-width:1260px){
.investMAINCARD{
    width: 240px;
    height: 170px;

}

}

@media(max-width:1065px){
    .InvestContent2 .InvestContentBox1 h5 {
        font-size: 28px;
        line-height: 25px;
        padding: 2% 0;
    }
    
    .InvestContent2 .InvestContentBox1 h6 {
        font-size: 14px;
        line-height: 26px;
    }
    
    .InvestContent2 .InvestContentBox1 h4 {
        font-size: 14px;  
    }
    .InvestContentOverview h5 {
        font-size: 20px;
        line-height: 25px;
    }
    
    .InvestContentOverview h6 {
        font-size: 12px;
        line-height: 20px;
    }
    
    .InvestContentOverview h4 {
        font-size: 12px;
        line-height: 20px;
    }
    
    .InvestContent2 .InvestContentBox1 {
        width: 50%;
    }
    .InvestContent2 .InvestContentBox2 {
        width: 45%;
    }
    .BusinessStart h2 {
        font-size: 25px;
        line-height: 25px;
    }
    
    .BusinessStart h6 {
        width: 100%;
        font-size: 15px;
        line-height: 30px;
    }
    
}


@media(max-width:912px){
    .InvestContent2 .InvestContentBox1 h5 {
        font-size: 25px;
        line-height: 22px;
        padding: 2% 0;
    }
    
    .InvestContent2 .InvestContentBox1 h6 {
        font-size: 14px;
        line-height: 26px;
    }
    
    .InvestContent2 .InvestContentBox1 h4 {
        font-size: 14px;  
    }
    .BusinessStart h2 {
        font-size: 22px;
        line-height: 25px;
    }
    
    .BusinessStart h6 {
        width: 100%;
        font-size: 14px;
        line-height: 30px;
    }
}



@media(max-width:820px){
    .investCont{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .investCont h5{
      text-align: center;
    }
}

@media(max-width:768px){
    .InvestContent2{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .InvestContentBox1{
        width: 100% !important;
    }
    .InvestContentBox2{
        width: 70% !important;
        margin-top: 5%;
        display: flex;
        justify-content: space-between !important;
    }
    .InvestContentBox2 h4{
        display: flex;
        justify-content: flex-end;
    }
}
@media(max-width:540px){
    .InvestContent2 .InvestContentBox1 h5 {
        font-size: 20px;
        line-height: 20px;
    }
    
    .InvestContent2 .InvestContentBox1 h6 {
        font-size: 12px;
        line-height: 20px;
    }
    
    .InvestContent2 .InvestContentBox1 h4 {
        font-size: 15px;  
    }
    .InvestContentOverview h5 {
        font-size: 20px;
        line-height: 20px;
    }
    
    .InvestContentOverview h6 {
        font-size: 12px;
        line-height: 15px;
    }
    
    .InvestContentOverview h4 {
        font-size: 10px;
        line-height: 15px;
    }
    .InvestContentBox2{
        width: 100% !important;

    }

}

@media(max-width:416px){
    .InvestContentBox2{
        width: 100% !important;
    }
    .BusinessStart h2 {
        font-size: 20px;
        line-height: 20px;
    }
    
    .BusinessStart h6 {
        width: 100%;
        font-size: 12px;
        line-height: 20px;
    }
    .investCont h5{
        font-size: 15px;
      }
    .investCont button{
        font-size: 15px;
        padding: 2% 4%!important;
        font-size: 12px;
      }
}


/* --------------------------------modal responsive------------------------------------ */


@media(max-width:500px) {

    .FaqsModal {
        width: 350px;
        height: 500px;
    }

    .FaqsModal h5 {
        font-size: 16px;
    }

    .FaqsModal h6 {
        font-size: 10px;
        line-height: 15px;
    }
    .ModalInput{
        display: flex;
        align-items: center !important;
    }
    .ModalInput input{
        width: 100%;
        padding: 10px 10px;
    }
    .ModalInput label{
       font-size: 10px;
       padding-bottom: 10px;
    }
    .ModalInput button {
        width: 40%;
        font-size: 12px;
        line-height: 22px;
        padding: 2.5% 0;
    }
    .FaqsModalButton a button{
       font-size: 10px;
       
    }
}
@media(max-width:415px) {
.InvestContentBox2{
    margin: 5% 0;
}
.InvestContentMain{
    margin-top: 5%;
}
.BusinessStart{
    margin-top: 10%;
    margin-bottom: 3%;
}
.investCont{
    margin-top: 6%;
    padding: 6% 0;
    margin-bottom: 10%;
}
.investCont h5{
    padding-bottom: 5%;
}
.investMAINCARD{
    width: 300px;
    height: 200px;

}

}
@media(max-width:360px) {

    .FaqsModal {
        width: 320px;
        height: 450px;
    }

    .FaqsModal h5 {
        font-size: 15px;
    }

    .FaqsModal h6 {
        width: 100%;
        font-size: 9px;
        line-height: 12px;
    }
    .ModalInput{
        display: flex;
        align-items: center !important;
    }
    .ModalInput input{
        width: 100%;
        padding: 8px 8px;
    }
    .ModalInput label{
       font-size: 10px;
       padding-bottom: 10px;
    }
    .ModalInput button {
        width: 45%;
        font-size: 10px;
        line-height: 20px;
        padding: 2.5% 0;
    }
    .FaqsModalButton a button{
       font-size: 8px;
       
    }
    .FaqsModalButton a button .icon{
       font-size: 18px;
       
    }
}