.RestMain {
    display: flex;
    width: 100%;
    height: 80vh;
    justify-content: center;
    align-items: center;
}

.RestStart {
    display: flex;
    align-items: center;
    width: 85%;
}

.RestBox1 {
    width: 45%;
}

.RestBox2 {
    width: 40% !important;
}
.RestBox2 img{
    width: 80% !important;
}

.RestBox1 h1 {
    color: #0D1216;
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    padding-bottom: 1%;
}

.RestBox1 h5 {
    color: rgba(13, 18, 22, 0.86);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding-bottom: 4%;

}

.RestBox1 button {
    background-color: #16469d;
    text-transform: capitalize;
    width: 20%;
    padding: 1.5% 0;

}

/* ------------------------------------------------media--------------------------------------------- */


@media(max-width:1280px) {
    .RestBox1 {
        width: 50%;
    }

    .RestBox2 {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }

    .RestBox2 img {
        width: 80%;
    }
}

@media(max-width:912px) {
    .RestBox1 h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .RestBox1 h5 {
        font-size: 14px;
        line-height: 20px;

    }

    .RestBox1 button {
        width: 30%;
    }

    .RestMain {
        height: 60vh;
    }


}

@media(max-width:768px) {
    .RestBox1 h1 {
        font-size: 28px;
        line-height: 40px;
    }

    .RestBox1 h5 {
        font-size: 14px;
        line-height: 20px;

    }

    .RestBox1 button {
        width: 30%;
    }

    .RestBox1 {
        width: 60%;
    }

    .RestBox2 {
        width: 40%;
    }

    .RestBox2 img {
        width: 100%;
    }

}

@media(max-width:540px) {
    .RestStart {
        width: 80%;
        display: flex;
        flex-direction: column-reverse;
    }

    .RestBox1 {
        width: 100%;
    }

    .RestBox2 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .RestBox2 img {
        width: 30%;
    }
    .RestMain {
        height: 40vh;
    }
    .RestBox1 h1 {
        font-size: 20px;
    }

    .RestBox1 h5 {
        font-size: 12px;
        width: 100%;
    }
}

@media(max-width:540px) {
    .RestStart {
        width: 85%;

    }
    .RestBox1 h1 {
        width: 100%;
    }



}