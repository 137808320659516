.MediaBGMain {
    display: flex;
    width: 100%;
    background:linear-gradient(3.13deg, rgba(0, 0, 0, 0.479) 1.8%, rgba(0, 0, 0, 0.008) 4.94%), url("https://res.cloudinary.com/dxluokypg/image/upload/v1681541438/media_and_gallery_qdajkr.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 40vh;
    align-items: center;
    justify-content: center;
}

.MediaBGStart {
    display: flex;
    flex-direction: column;
    width: 42%;
    text-align: center;
}

.MediaBGStart h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-bottom: 1%;
}

.MediaBGStart h5 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* text-align: center; */
    color: #FFFFFF;
}

@media(max-width:912px){
    .MediaBGMain {
        height: 35vh;
    }
    .MediaBGStart h2 {
        font-size: 30px;

    }
}
@media(max-width:540px){
    .MediaBGMain {
        height: 30vh;
    }
    .MediaBGStart h2 {
        font-size: 25px;

    }
}
@media(max-width:415px){
    .MediaBGMain {
        height: 25vh;
    }
    .MediaBGStart {
        width: 85%;
    }
    
    .MediaBGStart h2 {
        font-size: 20px;
        text-align: start !important;
        width: 100%;

    }
   
}