.OurBenefits {
    display: flex;
    width: 85%;
    margin-top: 5%;
}

.fitsStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    
}

.fitsStart h3 {
    display: flex;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: start;
    width: 100%;
    padding-bottom: 2%;
    text-transform: uppercase;
    color: #585858;
}

.fitsStart h1 {
    display: flex;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #585858;
    padding-bottom: 2%;
}

.fitsStart h6 {
    font-weight: 400;
    font-size: 15px;
    padding-bottom: 2%;
    line-height: 22px;
    color: #585858;
    text-align: justify;
}

@media (max-width: 1280px) {
    .OurBenefits {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        
    }
    .fitsStart{
        width: 100%;
        text-align: center;
        margin-bottom: 5%;
    }
    .fitsStart h3{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 900px) {
    .fitsStart h3 {
        display: flex;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
    }
    
    .fitsStart h1 {
        display: flex;
        font-weight: 700;
        font-size: 25px;
    }
    
    .fitsStart h6 {
        font-weight: 400;
        font-size: 15px;
    }
}
@media (max-width: 820px) {
    .fitsStartCont h6{
        width: 100% !important;
    }
}
@media (max-width: 540px) {
    .fitsStart h3 {
        display: flex;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
    }
    
    .fitsStart h1 {
        display: flex;
        font-weight: 600;
        font-size: 20px;
    }
    
    .fitsStart h6 {
        font-weight: 400;
        font-size: 12px;
    }
}
@media (max-width: 415px) {

    .fitsStart h3 {
        display: flex;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        width: 100%;
    }
    
    .fitsStart h1 {
        display: flex;
        font-weight: 600;
        width: 100%;
        font-size: 20px;
        line-height: 28px;
    }
    
    .fitsStart h6 {
        font-weight: 400;
        font-size: 12px;
        width: 100% !important;
    }
    .fitsStartCont h6{
        width: 100% !important;
    }
    .OurBenefits{
        margin-bottom: 5%;
    }
}