.PageMain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageStart {
  width: 88%;
  display: flex;
  margin: 5% 0;

}

.PageBox {
  width: 45%;
}


.PageBox h4 {
  color: #0D1216;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  padding-bottom: 2%;
  width: 90%;
}

.PageBox h6 {
  color: rgba(13, 18, 22, 0.86);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 2%;
}

form {
  display: flex;
  flex-direction: column;
}

form label {
  color: #585858;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  font-size: 15px;
  font-family: Inter;
  padding: 2% 0 1% 0;
}

form input {
  width: 70%;
  font-style: normal;
  font-family: Inter;
  padding: 0.8% 0;
  text-indent: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  line-height: 28px;
  border: 1px solid #CAD0DB;
  margin-bottom: 1%;

}

form .SubmitBtn {
  width: 28%;
  padding: 2% 0;
  margin: 4% 0;
  font-size: 14px;
  background-color: #16469d;
  text-transform: capitalize;
}

input::-webkit-input-placeholder {
  text-indent: 15px;
  font-size: 14px;
  font-weight: 500;
}

.Lockimg {
  display: flex;
  align-items: center;
}
.Lockimg img {
  width: 80%;
  object-fit: cover;
}
.PageBox button{
  width: 30%;
  text-transform: capitalize;
  font-size: 14px;
  background-color: #16469d;
  margin-top: 20px;
  cursor: pointer;
}

.errorsPermission{
  color: red;
  font-size: 12px;
}

/* ----------------------------------------------------media----------------------------------- */

@media(max-width:1300px) {
.PageBox h4 {
  font-size: 25px;
}
.PageBox h6 {
  font-size: 14px;
}
}
@media(max-width:1280px) {
  .PageBox {
    width: 50%;
  }
  .PageBox button{
    padding: 10px 4px;
    font-size: 12px;
  }
}
@media(max-width:1024px) {
  .PageBox {
    width: 60%;
  }
  .Lockimg img {
    width: 100%;
    object-fit: cover;
  }
}
@media(max-width:912px) {
  .PageBox h4 {
    font-size: 28px;
    line-height: 32px;
    width: 100%;
  }
  .PageBox h6 {
    width: 100%;
  }
  .PageBox button{
    width: 40%;
    font-size: 12px;
  }
}
@media(max-width:820px) {
  .PageBox h4 {
    font-size: 25px;
    line-height: 30px;
    width: 100%;
  }
  .PageBox h6 {
    width: 100%;
  }
  form label {
    font-size: 12px !important;
  }
  form input {
    width: 80%;
    font-size: 12px;
    line-height: 25px;
  }
  input::-webkit-input-placeholder {
    text-indent: 12px;
    font-size: 12px;
  }
  form .Pselect {
    width: 80% !important;
  }
}
@media(max-width:768px) {
  .PageBox h4 {
    font-size: 22px;
  }
  form input {
    width: 100%;
  }
  form .Pselect {
    width: 100% !important;
  }
  .PageStart{
    display: flex;
    width: 80%;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .PageBox {
    width: 100%;
  }
  .PageBox h4 {
    font-size: 20px;
  }
}

@media(max-width:460px){
  form input {
    padding: 1.5% 0;
  }
  .PageBox button{
    width: 40%;
    font-size: 10px;
  }
}
@media(max-width:415px){
  .PageBox input{
    padding: 2.5% 0 !important;
  }
  .PageBox select{
    padding: 4% 0 !important;
  }
}
