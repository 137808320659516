.disclaimermain {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 4%;
    margin-bottom: 4%;
}

.disclaimerStart {
    display: flex;
    width: 85%;
}

.disclaimerHeader {
    padding: 2%;
}

.disclaimerBox {
    width: 100%;
    border-bottom: 2px solid #979797;
}

.disclaimerBox h3 {
    padding-bottom: 4%;
    width: 100%;
    font-weight: 700;
    font-size: 30px;
    line-height: 58px;
    color: #0D0C22;
}

.disclaimerBox h4 {
    padding-bottom: 2%;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #585858;
}

.disclaimercontent {
    margin-top: 2%;
}

.disclaimercontent h6 {
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #616161;
}

@media(max-width:540px){
    .disclaimerBox h3 {
        font-size: 25px;
        line-height: 50px;
    }
    .disclaimerBox h4 {
        font-size: 15px;
        line-height: 15px;
    }
    
    .disclaimercontent h6 {
        font-size: 15px;
        line-height: 21px;
    }
    
}
@media(max-width:415px){
    .disclaimerBox h3 {
        font-size: 20px;
        line-height: 45px;
    }
    .disclaimerBox h4 {
        font-size: 12px;
        line-height: 15px;
    }
    
    .disclaimercontent h6 {
        font-size: 12px;
        line-height: 20px;
    }
    .disclaimerHeader {
        padding: 0;
    }
}