/* .CosmosBgMainStart{
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: red;
  justify-content: center;
  align-items: center;
}
.CosmosBgMain{
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  min-height: 100vh; 
}

.videoBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  object-fit: contain;
  width:100% !important;
  height: 50vh !important;
  background-color: aqua !important;
} */


/* ----------------------------------------------------------- */

/* .glow {
  font-size: 80px;
  color: #fff;
  text-align: center;
  animation: glow 0.5s ease-in-out infinite alternate;
}


@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #6281fe, 0 0 40px #498ff1, 0 0 50px #416bf6, 0 0 60px #1a3fd1, 0 0 70px #1e08af;
  }
  
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #2d36b3, 0 0 40px #2d36b3, 0 0 50px #2d36b3, 0 0 60px #2d36b3, 0 0 70px #2d36b3, 0 0 80px #2d36b3;
  }
}
*/


.CosmosBgMainStart{
  display: flex;
  width: 100%;
}
.CosmosBgMain{
  display: flex;
  width: 100%;
  /* background:linear-gradient(123.13deg, rgba(0, 0, 0, 0.479) 25.08%, rgba(0, 0, 0, 0.008) 104.94%), url(https://res.cloudinary.com/dxluokypg/image/upload/v1678085381/MainPageBanner_ghoimg.svg); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh; 
  align-items: flex-end;
  position: relative;

}

.CosmosBgMain2{
  display: none;
  width: 100%;
  background:linear-gradient(123.13deg, rgba(0, 0, 0, 0.479) 25.08%, rgba(0, 0, 0, 0.008) 104.94%), url(https://res.cloudinary.com/dxluokypg/image/upload/v1684392620/k_kosma_1_srzwpe.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 60vh; 
  align-items: flex-end;
  position: relative;
}

.videoBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1;
  object-fit: contain;
}


.CosmosBgContent{
  display: flex;
  position: absolute;
  width: 40%;
  bottom: 8%;
  left: 4%;
  flex-direction: column;
  padding: 10px;
}

@media(max-width:1080px){
  .CosmosBgContent h5{
      font-size: 18px;
  }
  .CosmosBgContent h1{
      font-size: 70px;
      line-height: 75px;
  }
}
@media(max-width:1024px){
  .CosmosBgContent h5{
      font-size: 17px;
  }
  .CosmosBgContent h1{
      font-size: 70px;
      line-height: 72px;
  }
}
@media(max-width:1010px){
  .CosmosBgContent h5{
      font-size: 17px;
  }
  .CosmosBgContent h1{
      font-size: 70px;
      line-height: 70px;
  }
}
@media(max-width:979px){
  .CosmosBgContent h5{
      font-size: 17px;
  }
  .CosmosBgMain{
      display: flex;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      min-height: 40vh; 
      align-items: flex-end;
      position: relative;
  }
}
@media(max-width:777px){
  .CosmosBgContent h5{
      font-size: 17px;
  }
  .CosmosBgContent h1{
      font-size: 60px;
      line-height: 70px;
  }
  
}

@media(max-width:700px){
  .CosmosBgContent{
      width: 50%;
  }
  .CosmosBgContent h5{
      font-size: 15px;
  }
  .CosmosBgContent h1{
      font-size: 60px;
      line-height: 60px;
  }
}
@media(max-width:540px){
  .CosmosBgContent{
      display: flex;
      flex-direction: column;
      width: 40%;
      padding: 0 !important;

  }

  .CosmosBgContent h5{
      font-size: 13px; 
      line-height: 20px;
  }
  .CosmosBgContent h1{
      font-size: 30px; 
      line-height: 40px;

  }
}
@media(max-width:418px){
  .CosmosBgMain{
      min-height: 30vh; 
  
  }
  .CosmosBgContent{
      width: 40%;
  }
  .CosmosBgContent h5{
      font-size: 12px; 
      line-height: 25px;
  }
  .CosmosBgContent h1{
      font-size: 30px; 
      line-height: 35px;

  }
  .CosmosBgMain{
      display: none;
  }
  
  .CosmosBgMain2{
      display: flex;    
  }
  
}
@media(max-width:363px){
  .CosmosBgContent{
      width: 70%;
  }
  .CosmosBgContent h5{
      font-size: 11px; 
  }
  .CosmosBgContent h1{
      font-size: 40px; 
      line-height: 40px;

  }
}