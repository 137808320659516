.invest_opp_main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
}

.invest_opp_content {
    width: 85%;
    display: flex;
    flex-direction: column;
}

.invest_opp_heading {
    color: #585858;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 3%;
}
.cardContent{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.cardContent h6 {
   text-decoration: none !important;
   font-size: 20px;
   font-weight: 500;
   color: #fff;
   text-shadow: 12px 12px 14px rgba(0, 0, 0, 1.9);
}
.cardContent h6 a{
   text-decoration: none !important;
}

@media(max-width:415px) {
    .invest_opp_heading {
        font-size: 20px;
    }
}