.MarketMain {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MarketStart {
    width: 85%;
    display: flex;
    flex-direction: column;
}
.MarketStart button{
    background-color: #16469d !important;
}

.MarketBox1 {
    width: 60%;
    margin: 2% 0;
}

.MarketImg{
    width: 100%;

}
.MarketImg img{
    width: 100%;

}
.MarketStart h2{
    font-weight: 600;
    font-size: 30px;
    line-height: 26px;
    color: #585858;
    padding-bottom: 2%;
}
.MarketBox1 h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #333333;
    padding-bottom: 2%;
}

.MarketBox1 h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #585858;
    padding-bottom: 3%;
}

/* .MarketBox2 h2,
.MarketBox3 h2,
.MarketBox4 h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #333333;
    padding-bottom: 2%;
}

.MarketBox2 h6,
.MarketBox3 h6,
.MarketBox4 h6 {
    font-weight: 400;
    font-size: 13px;
    line-height: 30px;
    color: #585858;
    padding-bottom: 3%;
}

.MarketBox4 button {
    width: 30%;
    padding: 2% 0;
    background: #4A8CFF;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 4%;
} */
/* ----------------------- */

.ModalInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.ModalInput form{
    width: 100%;
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}


.ModalInput input {
    width: 100%;
    padding: 2% 0;
    border-radius: 5px;
    text-indent: 10px;
    margin: 3% 0;
    border: 1px solid gray;
}
.ModalInput input:hover {
    border-color: #16469d !important;
}



.ModalInput button {
    margin-top: 3%;
    margin-bottom: 2%;
    width: 30%;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 24.012px;
    border-radius: 4px;
    background: #16469d;
    padding: 2.5% 0;
    text-transform: capitalize;
}

.MarketStart button {
    margin: 5% 0 4% 0;
    width: 15%;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: 24.012px;
    border-radius: 4px;
    background: #3578DE;
    padding: 1% 0;
    text-transform: capitalize;
}



.FaqsModal h1 {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    font-weight: 500;
    color: #939393;
    margin: 3% 0;
}

.FaqsModal h1:before,
.FaqsModal h1:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
}

.FaqsModal h1:before {
    margin-right: 10px;
}

.FaqsModal h1:after {
    margin-left: 10px;
}


.MarketBox1 h6:hover{
    color: #1e1e1e;
}

/* ---------------------------------------------------------------------------- */

@media(max-width:1024px){
    .MarketStart h2{
        font-size: 25px;
    }
    .MarketBox1 h2 {
        font-size: 18px;
    }
    
    .MarketBox1 h6 {
        font-size: 12px;
    }
    .MarketStart button {
        width: 18%;
        font-size: 14px;
    }
}
@media(max-width:768px){
    .MarketStart h2{
        font-size: 25px;
        width: 100%;
    }
    .MarketBox1 h2 {
        font-size: 18px;
        width: 100%;
    }
    
    .MarketBox1 h6 {
        font-size: 12px;
        width: 100%;
    }
    .MarketBox1 {
        width: 80%;
        margin: 2% 0;
    }
    
    .MarketImg{
        width: 100%;
    
    }
    .MarketImg img{
        width: 100%;
    
    }
    .MarketStart button {
        width: 25%;
        font-size: 14px;
    }
}
@media(max-width:540px){
    .MarketStart h2{
        font-size: 22px;
    }
    .MarketBox1 h2 {
        font-size: 15px;
    }
    
    .MarketBox1 h6 {
        font-size: 12px;
    }
    .MarketBox1 {
        width: 100%;

    }
    .MarketStart button {
        width: 30%;
        font-size: 13px;
    }
}
@media(max-width:416px){
    .MarketStart h2{
        font-size: 20px;
        line-height: 22px;

    }
    .MarketBox1 h2 {
        font-size: 15px;
        line-height: 22px;

    }
    
    .MarketBox1 h6 {
        font-size: 12px;
        line-height: 25px;

    }
    .MarketBox1 {
        width: 100%;

    }
    .MarketStart button {
        width: 40%;
        font-size: 12px;
    }
}

/* --------------------------------modal responsive------------------------------------ */


@media(max-width:500px) {

    .FaqsModal {
        width: 350px;
        height: 500px;
    }

    .FaqsModal h5 {
        font-size: 16px;
    }

    .FaqsModal h6 {
        font-size: 10px;
        line-height: 15px;
    }
    .ModalInput{
        display: flex;
        align-items: center !important;
    }
    .ModalInput input{
        width: 100%;
        padding: 10px 10px;
    }
    .ModalInput label{
       font-size: 10px;
       padding-bottom: 10px;
    }
    .ModalInput button {
        width: 40%;
        font-size: 12px;
        line-height: 22px;
        padding: 2.5% 0;
    }
    .FaqsModalButton a button{
       font-size: 10px;
       
    }
}

@media(max-width:415px) {
.MarketBox1{
    margin: 8% 0;
}
.MarketStart button{
    padding: 2.5% 0;
  }
}
@media(max-width:360px) {

    .FaqsModal {
        width: 320px;
        height: 450px;
    }

    .FaqsModal h5 {
        font-size: 15px;
    }

    .FaqsModal h6 {
        width: 100%;
        font-size: 9px;
        line-height: 12px;
    }
    .ModalInput{
        display: flex;
        align-items: center !important;
    }
    .ModalInput input{
        width: 100%;
        padding: 8px 8px;
    }
    .ModalInput label{
       font-size: 10px;
       padding-bottom: 10px;
    }
    .ModalInput button {
        width: 45%;
        font-size: 10px;
        line-height: 20px;
        padding: 2.5% 0;
    }
    .FaqsModalButton a button{
       font-size: 8px;
       
    }
    .FaqsModalButton a button .icon{
       font-size: 18px;
       
    }
}