.EpcAggrMain {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
}

.EpcAggrStart {
    display: flex;
    flex-direction: column;
    width: 70%;
}
.EpcAggrStart h1{
    color: #585858;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: -0.3px;
    padding-bottom: 5%;
}
.EpcAggrCard {
    display: flex;
    flex-wrap: wrap !important;
    gap: 40px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4%;
}


.EpcAggrBox {
    display: flex;
    flex-direction: column;
    width: 30%;
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.20);
    cursor: pointer;
    transition: 0.4s ease-in-out;
}
.EpcAggrBox:hover{
    transform: scale(1.1);

}

.EpcAggrImg {
    width: 100%;
}

.EpcAggrImg img {
    width: 100%;
}
.EpcAggrContent {
    padding: 0 0 0 4% ;

}
.EpcAggrContent h4 {
    color: #454245;
    font-size: 14.7px;
    line-height: 18.2px;
    letter-spacing: 0.1px;
    padding: 2% 0;
}

.EpcAggrContent h5 {
    color: #000;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    padding: 2% 0;
}

.EpcAggrContent a {
    color: #1264A3;
    font-size: 13.7px;
    font-weight: 700;
    line-height: 15.75px;
    letter-spacing: 0.8px;
    text-decoration: none;
    text-transform: uppercase;

}
.EpcAggrdiv{
    margin-top: 12%;
    margin-bottom: 5%;
}


@media(max-width:1300px){
    .EpcAggrStart {
        width: 80%;
    }
    .EpcAggrContent h4 {
        font-size: 13px;
        line-height: 18.2px;
    }
    
    .EpcAggrContent h5 {
        font-size: 18px;
        font-weight: 700;
    }
    .EpcAggrContent a {
        font-size: 12px;
    }
    .EpcAggrStart h1{
        font-size: 28px;
    }
}
@media(max-width:1024px){
    .EpcAggrStart {
        width: 80%;
    }
    .EpcAggrContent h4 {
        font-size: 12px;
        line-height: 18.2px;
    }
    
    .EpcAggrContent h5 {
        font-size: 15px;
        font-weight: 700;
        padding: 0 !important;

    }
    .EpcAggrContent a {
        font-size: 10px;
    }
    .EpcAggrStart h1{
        font-size: 26px;
    }
}
@media(max-width:820px){
    .EpcAggrCard{
        display: flex;
        gap: 20px !important;
        flex-wrap: wrap;
    }
    .EpcAggrBox {
        display: flex;
        width: 45%;
    }
    .EpcAggrContent h4 {
        width: 100%;
        font-size: 12px;
        line-height: 18.2px;
    }
    
    .EpcAggrContent h5 {
        width: 100%;
        font-size: 14px;
        font-weight: 700;

    }
    .EpcAggrStart h1{
        font-size: 24px;
    }
}
@media(max-width:540px){
    .EpcAggrCard{
        display: flex;
        gap: 20px !important;
        flex-wrap: wrap;
    }
    .EpcAggrBox {
        display: flex;
        width: 60%;
    }
    .EpcAggrContent h4 {
        width: 100%;
        font-size: 12px;
        line-height: 18.2px;
    }
    
    .EpcAggrContent h5 {
        width: 100%;
        font-size: 14px;
        font-weight: 700;

    }
    .EpcAggrStart h1{
        font-size: 22px;
    }
}

@media(max-width:416px){
    .EpcAggrBox {
        display: flex;
        width: 100%;
    }
    .EpcAggrStart h1{
        font-size: 18px;
    }
}