.CSMsg {
    font-weight: 300;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    color: #000;
}

.ErrorBtn1{
    
    background-color: #F5AC38;
    font-size: 15px;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    text-transform: none;  
    width: 105px;
 
}

.ErrorBtn1:hover{
    background-color: #F5AC38;
    font-weight: 500;
   
}

/* ---------------------------------------- */

.glitchwrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #222;
    border-radius: 10px;
    padding: 4% 0;

 }
 
 .glitch {
    position: relative;
    font-size: 50px;
    font-weight: 700;
    line-height: 1.2;
    color: #fff;
    letter-spacing: 5px;
    z-index: 1;
 }
 
 .glitch:before,
 .glitch:after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
 }
 
 .glitch:before {
    animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
    color: #0ff;
    z-index: -1;
 }
 
 .glitch:after {
    animation: glitch-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
    color: #ff00ff;
    z-index: -2;
 }
 
 @keyframes glitch-color {
    0% {
       transform: translate(0);
    }
 
    20% {
       transform: translate(-3px, 3px);
    }
 
    40% {
       transform: translate(-3px, -3px);
    }
 
    60% {
       transform: translate(3px, 3px);
    }
 
    80% {
       transform: translate(3px, -3px);
    }
 
    to {
       transform: translate(0);
    }
 }