:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}

.mainsec {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.innersec {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 85%;
    padding-top: 5%;
    padding-bottom: 10%;
}

.form_sec {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 30%;
    margin-top: 2%;
}

.Form_heading {
    font-size: 30px;
    font-weight: 700;
    color: #585858;
    line-height: 36px;
}

.form_inputtxt {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #000 !important;
    line-height: 16px !important;
    padding-bottom: 5% !important;  
}

.form_inputtxt1 {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #585858 !important;
    line-height: 16px !important;
}


.uploadsesction {
    display: flex;
    width: 100%;
    height: 35px;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
}


.uploadbox {
    width: 50%;
}

.uploadsesction input[type=file] {
    width: 100%;
    border-radius: 0 10px 10px 0;
    box-sizing: border-box;
    font-size: 15px;

}

.brousebtn {
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #fff !important;
    line-height: 11px !important;
    text-transform: capitalize;
}

.doctxt {
    font-size: 10px !important;
    font-weight: 300 !important;
    color: #000 !important;
    line-height: 11px !important;
    padding-bottom: 5% !important;
    padding-top: 1%;
}

.formbtn {
    margin-top: 5%;
    color: #fff;
    border-radius: 10px;
    height: 49px;
    font-size: 18px;
    font-weight: 400;
    background-color: #16469D;
    text-transform: capitalize;
}

.lastsection {
    display: flex;
    width: 100%;
    flex-direction: column;

}

.coverltrtxt::placeholder {
    font-size: 15px !important;

}

.coverltrtxt {
    font-size: 15px !important;
    font-weight: 300 !important;
    color: #000 !important;
    line-height: 20px !important;
}

.inputBox {
    color: transparent;
    display: flex;
    justify-content: flex-end !important;
    background-color: #16469D;
    border: none;
    border-top: 2px solid #16469D;
    border-bottom: 2px solid #16469D;
}

.ImgInput_footer {
    font-size: 14px;
}

.uploadsesction {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    overflow: hidden !important;
    
}
.uploadsesction .inputBox{
   width: 35%;
}
/* 
.ImgInput_footer{
    width: 90%;
    display: flex;
    overflow: hidden;
    border: 2px solid black !important;
} */
.inputBoxUpper{
    width: 60%;
    height: 100%;
    overflow:scroll;
    display: flex;
    font-size: 10px;
    /* overflow: hidden; */
    overflow-x: hidden;
}
@media(max-width:1280px) {
    .form_sec {
        width: 40%;
    }
}

@media(max-width:912px) {
    .form_sec {
        width: 50%;
    }

    .uploadsesction {
        display: flex;
        width: 100%;

    }
}

@media(max-width:768px) {
    .Form_heading {
        font-size: 28px;
        line-height: 30px;
    }

    .form_inputtxt {
        font-size: 12px !important;
        padding-bottom: 4% !important;
        padding-top: 4%;

    }

    /* .lastsection {
        display: flex;
        width: 50%;
        flex-direction: column;

    } */
}

@media(max-width:600px) {
    .form_sec {
        width: 100%;
    }

    /* .lastsection {
        display: flex;
        width: 100%;
        flex-direction: column;

    } */
}

@media(max-width:540px) {
    .Form_heading {
        font-size: 25px;
        line-height: 30px;
    }

    .form_inputtxt label {
        font-size: 12px !important;
    }

    .uploadbox {
        width: 270px;
    }

    .uploadsesction button {
        width: 150px;
        height: 40px;
        margin-left: -2%;


    }

    .formbtn {
        margin-top: 5%;
        color: #fff;
        border-radius: 10px;
        height: 40px;
        font-size: 15px;
        font-weight: 400;
        background-color: #16469D;
        text-transform: capitalize;
    }
}

@media(max-width:415px) {
    .Form_heading {
        font-size: 20px;
        line-height: 25px;
    }

    .form_inputtxt label {
        font-size: 12px !important;
    }

    .uploadbox {
        width: 270px;
    }

    .uploadsesction button {
        width: 150px;
        height: 40px;
        margin-left: -2%;


    }

    .formbtn {
        margin-top: 5%;
        color: #fff;
        border-radius: 10px;
        height: 40px;
        font-size: 15px;
        font-weight: 400;
        background-color: #16469D;
        text-transform: capitalize;
    }

    .coverltrtxt {
        font-size: 12px;
    }
}



.fileinputcontainer {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    background-color: #f7f7f7; 
    border: 0.5px solid #b8b5b5;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;

}


.fileinputcontainer input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 100px;
    width: 100%; 
    height: 100%; 
}

/* Style the custom button */
.custombutton {
    background-color: #16469D;
    color: #fff;
    padding: 10px 20px;
    border: 1px solid #16469D;
    border-radius: 5px;
    cursor: pointer;
    height: 100%; 
    line-height: 1;
}

.custombuttonlabel:hover {
    cursor: pointer;
}

.custombuttonlabel {
    font-weight: bold;
    background-color: #16469D;
}



.filename {
   margin-top: 9px;
   font-size: 12px;
   padding-left: 4px;
}