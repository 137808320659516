:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}



.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading {
    display: flex;
    width: 100%;
}

.TeamContent {
    width: 85%;
    margin-bottom: 5%;
}

.heading {
    padding-top: 3%;
    padding-bottom: 3%;
    font-size: 30px;
    font-weight: 700;
    line-height: 23px;
    color: #585858;
    margin-left: none !important;
}

.card1 {
    display: flex;
    justify-content: space-between;
    width: 80% !important;
    border-radius: 4px;
    display: flex;
    border: 1px solid rgb(226, 226, 226);
    cursor: pointer;
    height: auto
}


.cardTop
{
    width: 100%;
    height: 80%;

}
.cardTop > img {
    width: 100%;
    height: 35vh;
    box-shadow: 0 1px 5px -1 #000000, 0 1px 5px -1 #000000;
    object-fit: cover;
}

.TeamHead {
    font-size: 20px;
    font-weight: 500;
    color: #585858;
}

.Teampara {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.cardBottom {
    display: flex;
    box-shadow: 0 1px 5px -1 #000000, 0 1px 5px -1 #000000;
    flex-direction: column;
    width: 100%;
    background-color: #fbfbfb;
    padding: 5%;
}
.modalStyle {
    width: 80%;
    height: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    max-width: 1920px;
    border-top: 8px solid #16469D;
    border-radius: 4px;
    padding: 20px 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    display: flex;
    flex-direction: column;
    gap: 5px;

}

.modalStart {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    height: 95%;
}

.modalStart >img
{
    width: 100%;
    height: 100%;
    min-width: 500px;
    max-width: 550px;
    /* object-fit: fill; */
    object-fit: cover;
}

.profileDescritpion {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;

}


@media (max-width: 1400px) {
    .TeamHead {
        font-size: 17px;
    }

    .Teampara {
        font-size: 12px;
    }

}

@media (max-width: 1190px) {
    .TeamHead {
        font-size: 15px;
    }

    .Teampara {
        font-size: 11px;
    }

}

@media (max-width: 1050px) {
    .TeamHead {
        font-size: 14px;
    }

    .Teampara {
        font-size: 10px;
    }

}

@media (max-width: 1024px) {

    .TeamHead {
        font-size: 12px;
    }

    .Teampara {
        font-size: 8px;
    }

    .heading {
        font-size: 25px;
    }

    .card1 {
        width: 90%;
    }
}

@media (max-width: 600px) {

    .heading {
        font-size: 22px;
    }

    .TeamHead {
        font-size: 13px;
    }

    .Teampara {
        font-size: 11px;
    }
    /* .card1 {
        height: 70vh
    }
    .cardTop{
    width: 100%;
    height: 60%;

} */

}

@media (max-width: 415px) {
    .card1 {
        width: 100%;
        margin: 0;
    }

    .TeamContent {
        width: 85%;
    }

    .heading {
        font-size: 20px;
        font-weight: 700;
    }

    .TeamHead {
        font-size: 10px;
    }

    .Teampara {
        font-size: 8px;
    }
    .modalStyle {
        width: 80%;
        height: 80%;
    }
    .modalStart {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 95%;
    }



}


@media screen and (max-width: 768px) {
    .modalStart {
        flex-direction: column;
        
    }
    .modalStart > img {
        width: 150px;
        height: 150px;
        justify-content: center;
    }
    .modalStart .profileDescritpion>h2 {
        font-size: 18px ;
    }

   

}