.MainCareer {
  display: flex;
  width: 100%;
  flex-direction: column;
  background: #f5f4f4;
  padding-top: 5%;
  margin-bottom: 5%;
}

.FirstCareerTab {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Careerheads {
  width: 40%;
  overflow-x: none;
}

.Careerheads h4 {
  font-weight: 510;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #585858;
}

.Careerheads h2 {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #585858;
  padding-bottom: 5%;
}

.Careerheads h6 {
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  padding-bottom: 5%;
}

.Careerheads2 {
  width: 100%;
  display: none;
}

.Careerheads2 h4 {
  width: 100%;
  font-size: 20px;
  color: #585858;
  font-weight: 600;
  text-transform: uppercase;
}

.Careerheads2 h6 {
  width: 100%;
  font-size: 12px;
  color: #585858;
  font-weight: 400;
}

/* --------------------------------------------- */
.SecondCareerTab {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 2%;
}

.TabsMain {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5%;
  background-color: #fff;

}

.TabsMain h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #585858;

}


.TabLabel {
  color: #585858;
  font-weight: 500;
  align-items: self-start;
  padding-left: 8%;
  font-size: 20px;
  width: 100%;
  line-height: 24px;
  text-transform: capitalize;
}

.TabStart {
  display: flex;
  width: 80%;
}

.CareerPanel {
  display: flex;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
  padding: 3%;
}

.CareerPanel h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #585858;

}

.CareerPanel h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 5%;
  color: #D4D4D4;
}

.PanelBox {
  width: 60%;
  align-items: center;
  justify-content: flex-end;
}

.PanelBox2 {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.PanelBox3 {
  display: flex;
  flex-direction: column;
  width: 55%;
}

.BoxIcon {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #D4D4D4;
}

.Tab label {
  font-size: 50px;
}

.PanelStart {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.BoxIcon .HoverIcon:hover {
  color: #16469D;
}

.PanelHide {
  display: none;

}

.PanelHide h4 {
  display: flex;
  font-size: 12px;
  color: #868686;
}

.PanelHide h6 {
  display: flex;
  font-size: 12px;
  color: #868686;
}

.BoxIcon button {
  display: none;
  border-radius: 25px;
  color: #585858;
  border: 1px solid #585858;
  padding: 1% 10%;
}

.BoxIcon button:hover {
  background-color: #94b7f7;
}

.TabDiv h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #585858;
  padding-left: 9%;
  padding-top: 5% !important;
  padding-bottom: 5% !important;
}

.css-xnzjbj-MuiTypography-root {
  margin: 0 !important;
}

.MainCareerHidebar2 {
  display: none;
}

.TabsMainHideBarHide {
  display: none;
}
.noJobRole{
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  background-color: #fff;
  color: #585858;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 4px;
}

@media(max-width:1320px) {
  .CareerPanel h4 {
    font-size: 17px;
  }

  .CareerPanel h6 {
    font-size: 12px;
  }

  .PanelBox {
    width: 21%;
  }

  .PanelBox2 {
    width: 21%;
    justify-content: center;
  }
  .TabLabel {
    font-size: 15px;
  }
}

@media(max-width:1210px) {
  .CareerPanel h4 {
    font-size: 17px;
  }

  .CareerPanel h6 {
    font-size: 12px;
  }

  .PanelBox {
    width: 21%;
  }

  .PanelBox2 {
    width: 21%;
    justify-content: center;
  }

  .TabLabel {
    font-size: 13px;
  }
}

@media(max-width:935px) {

  .Careerheads {
    width: 80%;
    overflow-x: none;
  }

  .TabStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .TabDiv {
    display: flex !important;
    justify-content: center;
    align-items: center;
    width: 70% !important;
  }

  .TabDiv h6 {
    padding-left: 0 !important;
    text-align: center;
    width: 100% !important;
  }
 

  .TabsMain {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 20%;
    text-align: center !important;
  }

  .TabLabel {
    align-items: normal;
  }

}

@media(max-width:605px) {
  .TabsMain {
    padding: 0 !important;

  }
}

@media(max-width:1160px) {
  .CareerPanel h4 {
    font-size: 15px;
  }

  .CareerPanel h6 {
    font-size: 10px;
  }

  .PanelBox {
    width: 21%;
  }

  .PanelBox2 {
    justify-content: center;
    align-items: center;
    width: 21%;
  }

  .Careerheads {
    width: 60%;
    overflow-x: none;
  }

}

@media(max-width:821px) {

  .Careerheads h4 {
    width: 100%;
    font-size: 17px;
    text-align: center;
  }

  .Careerheads h2 {
    width: 100%;
    font-size: 25px;
    text-align: center;
  }

  .Careerheads h6 {
    width: 100%;
    font-size: 13px;
    text-align: center;
  }
}

@media(max-width:769px) {

  .Careerheads {
    width: 80%;
    overflow-x: none;
  }

  .TabStart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .TabLabel {
    align-items: normal;
    text-align: center;
  }

}

@media(max-width:602px) {
  .CareerPanel h4 {
    font-size: 15px;
  }

  .PanelBox2 {
    width: 25%;

  }

  .CareerPanel h6 {
    font-size: 10px;
  }

  .TabsMain {
    width: 40%;
  }
}

@media(max-width:540px) {

  .PanelBox2 {
    width: 25%;

  }

  .PanelBox2 h6 {
    font-size: 11px !important;
  }

  .CareerPanel h4 {
    font-size: 12px;
  }

  .CareerPanel h6 {
    font-size: 10px;
  }

  .PanelBox {
    width: 23%;
  }

  .PanelBox2 {
    width: 23%;
  }

  .Careerheads h4 {
    font-size: 15px;
  }

  .Careerheads h2 {
    font-size: 20px;
  }

  .Careerheads h6 {
    font-size: 12px;
  }
  .noJobRole{
    height: 100px;
    font-size: 15px;

  }
}

@media(max-width:445px) {
  .Careerheads {
    display: none;
  }

  .Careerheads2 {
    width: 85%;
    margin-top: 5%;
    display: block;
  }

  .Careerheads2 h4 {
    padding-bottom: 2%;
  }

  .TabDiv {
    display: none;
    height: 400px !important;
    background-color: #585858;
  }

  .TabDiv h6 {
    display: none;
  }

  .TabsMain {
    width: 100% !important;
    flex-direction: row !important;
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center;
    align-items: center;
  }

  .TabLabel {
    width: 70%;
    font-size: 12px;
    display: flex;
    padding: 0 !important;
    margin-bottom: 2%;
    border-radius: 25px;
    border: 1px solid black;
    margin-left: 15%;

  }

  .PanelBox2 {
    display: none;
  }

  .PanelHide {
    display: block;
  }

  .PanelBox {
    padding: 0 2%;
    width: 100%;
  }

  .PanelBox h4 {
    width: 100%;
    font-size: 15px;
    padding-top: 2%;
  }

  .HoverIcon {
    display: none;
  }

  .BoxIcon button {
    display: block;
  }
}

@media(max-width:415px) {
  .MainCareerHidebar {
    width: 100%;
  }

  .SecondCareerTab {
    width: 100%;
  }

  .TabStart {
    width: 100%;

  }

  .TabDiv {
    width: 100% !important;
    height: 50px !important;

  }

  .CareerPanel {
    border-radius: 4px;
  }

  .TabDivHide h6 {
    display: none;
  }

  .CareerPanel {
    background-color: #f5f4f4;
  }

  .Careerheads2 {
    display: none;
  }

  .PanelStart {
    background-color: #fff;
  }

  .PanelBox button {
    background-color: #fff;
  }

  .MainCareer {
    background-color: #fff;
  }

  .TabsMainHideBarHide {
    /* display: block; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 95%;
    margin: 0 !important;
    padding: 0 !important;
  }

  .TabsMain {
    display: none !important;

  }

  .TabLabel {
    width: 60%;
    padding: 0 !important;
    margin-left: 0 !important;
    margin-right: 3%;


  }
  .PanelBox h4{
    font-size: 12px;
  }
  .PanelBox h6{
    font-size: 10px;
  }
  .BoxIcon button{
    font-size: 12px;
  }
  .noJobRole{
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height:75px;
    font-weight: 600;
    background-color: #f5f4f4;
    border-radius: 6px;
  }
}


/*  carrer Tab Pannel */

.rJobContainerMain
{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;

}
.rJobContainer
{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  max-width: 1000px;
  width: 100%;
  padding: 0 10px;
  gap: 20px;
  flex-wrap: wrap;
 
}
.rSideBar
{
  display: flex;
  max-width: 250px;
  /* max-width: 250px; */
  width: 100%;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px 0;
  flex-direction: column;
  gap: 10px;
  min-height: 60vh;
}
.rSideBar >h4
{
font-weight: 700;
font-size: 20px;
line-height: 24px;
padding: 0 15px;
}
.rDeptList
{
list-style: none;
display: flex;
flex-direction: column;

}
.rDeptListItem
{
  padding: 10px 15px;
  font-weight: 500;
font-size: 18px;
line-height: 24px;
cursor: pointer;
color: #585858;

}
.rDeptListItemSelected
{
  color: #16469D;
background-color: #F6FAFF;
}
.rDeptListItem:hover
{
color: #16469D;
background-color: #F6FAFF;
}

.rJobPannel
{
 flex-grow: 1;

  display: flex;
  justify-content: center;
  height: 100%;
  min-width: 300px;
  flex-direction: column;
  gap: 20px;
}

.rNoJob
{
  display: flex;
width: 100%;
background-color: white;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
padding: 30px;
height: 100%;
min-height: 60vh;
justify-content: center;
align-items: center;
font-weight: 600;
}

.rJobRow
{
  display: flex;
  background-color: white;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
padding: 20px 20px;
width: 100%;
justify-content: space-between;
align-items: center;
text-decoration: none;
}
.rJobRowInner
{
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  align-items: center;
  gap: 10px;
  
}

.rJobRowInner >h5
{
width: 40%;
font-size: 20px;
line-height: 20px;
font-weight: 500;
color: #585858;
min-width: max-content;
}
.rJobColumn
{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.rJobColumn >h5
{
  
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #585858;
  min-width: max-content;
}
.rJobColumn >p
{
  font-size: 15px;
  color: #D4D4D4;
}
.rJobbtn
{
  display: flex;
  justify-content: flex-end;
  align-items: center;

}
.rJobbtn > span
{
  font-size: 14px;
  padding: 6px 10px;
  border: 1px solid #16469D;
  color: #16469D;
  border-radius: 4px;
  transition: 0.3s all ease-in-out;
  min-width: max-content;


}
.rJobbtn > span:hover
{
  background-color: #16469D;
  color: white;
}

@media screen and (max-width:900px) {
  .rSideBar
  {
    max-width: 100%;
    min-height: auto;
    gap: 0;
  }
  .rDeptList
  {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    overflow-x:auto ;
    gap: 10px;
    padding: 10px 15px 10px 15px;
  }
  
  .rDeptListItem
  {
    min-width: max-content;
    border: 1px solid #585858;
    border-radius: 8px;

  }
  .rDeptListItemSelected
  {
    border: 1px solid #16469D;
  }
  .rNoJob {
    min-height: 20vh;
  }
}

@media screen and (max-width:768px) {
  .rJobRow
  {
    /* align-items: flex-start; */
    background-color: #f5f4f4;
  }
  .rJobRowInner
  {
    flex-direction: column;
    align-items: flex-start;
  }
  .rJobRowInner > h5
  {
    font-size: 18px;
  }
  .rJobColumn
  {
    gap: 5;
    flex-direction: row;
  }
  .rJobColumn > p
  {
    font-size: 12px;
    font-weight: 600;
    color: #868686;
  }
  .rJobColumn > h5
  {
    font-size: 12px;
    color: #868686;
    font-weight: 600;
  }
  .rJobbtn > span
  {
    font-size: 14px;
    border: 1px solid #585858;
    color: #585858;

  }
  .mHide
  {
    display: none;
  }
}