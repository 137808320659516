.MediaGalleryMain {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px 10px;
}

.MediaGalleryStart {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1300px;
    justify-content: center;
    align-items: center;
}

.MediaGalleryStart>p {
    font-weight: 600;
    font-size: 30px;
    line-height: 23px;
    color: #585858;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 20px 0;
}


.BoxStart>h2 {
    font-weight: 700;
    font-size: 25px;
    line-height: 23px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.BoxStart {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 0;
}

.BoxStart>.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content:space-between;
    position: relative;
    padding-bottom: 10px;
}
.BoxStart>.header::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4px;
    background-color: #16469d;
    border-radius: 10px;
}
.header>p {
    font-weight:600;
    font-size: 1.6rem;
    line-height: 23px;
    color: #16469d;
    cursor: pointer;
}
.header>a {
    font-weight:500;
    font-size: 1rem;
    line-height: 23px;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
    cursor: pointer;
}
.header>a:hover{
    color: #16469d;

}

.GridContainer
{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(300px,100%),1fr));
    gap: 15px;
    width: 100%;
    padding: 10px 0;
}


.MediaBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #e8f0fa;
    height: auto;
    border-radius: 2%;
    margin: 10px 0;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    color: #000000;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.MediaImg {
    height: 200px;
    width: 100%;
   
    overflow: hidden;
    border-radius: 2%;

}

.MediaImg>img {
    width: 100%;
    object-fit: cover;
    transition: all 0.3s ease-in-out;
    object-position: center center;
    height: 100%;

}

.MediaBox:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    

}

.MediaImg>img:hover {
    transform: scale(1.2);
}

.imgInfo {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    width: 100%;
    

}

.imgInfo>div>span {
    font-size: 0.8rem;
    color: rgb(95, 95, 95);
    font-weight: 600;

}
.imgInfo>p {
    font-size: 0.8rem;
    color: rgb(95, 95, 95);
    font-weight: 600;

}

.imgInfo>p svg {
    color: #585858;
    cursor: pointer;
}

.imgInfo>p svg:hover {
    color: #16469d;

}

.imgInfo>span {
    font-size: 0.9rem;
    color: black;
    font-weight: 600;
}
.imgInfo>div>a>svg {
    color: black;
}

.imgInfo>div>a>svg:hover {
    color: #16469d;
}



.filter{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.filter>p{
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
}
.filter>p:hover{
    color: #16469d;
}

/* .BoxStart2 {
    width: 95%;
    display: none;

}
 */


/* @media(max-width:1250px) {
    .MediaBox {
        width: 45%;
        display: flex;
        margin-bottom: 3%;
    }
}

@media(max-width:900px) {
    .MediaBox {
        width: 80%;
    }

    .MediaHead h5 {
        font-size: 25px;
    }
}

@media(max-width:820px) {
    .MediaHead h5 {
        font-size: 20px;
    }

}

@media(max-width:415px) {
    .MediaBox {
        width: 30%;
        display: flex;
        margin-bottom: 3%;
    }
    .BoxStart2 {
        width: 95%;
        display: block;
        margin-bottom: 5%;
    
    }
    .BoxStart2 .MediaBox2 {
        width: 100% !important;

    }
    .MediaBox2 img {
        border-radius: 2%;
        width: 100%;
        object-fit: fill !important;
    
    }
    .BoxStart{
        display: none;
    }
    
} */
 