*{
    margin: 0;
    padding: 0;
}
.bannerStart{
    background:linear-gradient(123.13deg, rgba(0, 0, 0, 0.479) 25.08%, rgba(0, 0, 0, 0.008) 104.94%), url('https://res.cloudinary.com/dxluokypg/image/upload/v1681550750/Join_us_2_eclrgq.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40vh;

}
.banner1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 46% !important;
}
.banner1 h2{
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}
.banner1 h6{
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}
 .banner1 h4{
    display: none;
}

@media(max-width: 912px) {
    .banner1{
        width: 100%;
        height: 35vh;
    }
    .banner1 h2{
        font-size: 32px;

    }
    .banner1 h6{
        font-size: 15px;

    }
}
@media(max-width: 820px) {
    .banner1{
        width: 100%;
        height: 30vh;
    }
    .banner1 h2{
        font-size: 25px;

    }
    .banner1 h6{
        font-size: 12px;
    }
}
@media(max-width: 768px) {
    .banner1 h2{
        font-size: 20px;

    }
    .banner1 h6{
        font-size: 10px;
    }
}
@media(max-width: 540px) {
    .banner1 h2{
        font-size: 15px;
        width: 100%;

    }
    .banner1 h6{
        font-size: 8px;
        width: 100%;
    }
}
@media(max-width: 415px) {
    .bannerStart{
        height: 25vh;
    
    }
    .banner1 h2{
        text-align: start;
        padding-bottom: 2%;
        font-size: 20px;
        font-weight: 600;
        
    }
    .banner1 h6{
       display: none;
    }
    .banner1 h4{
        display: flex;
        font-size: 12px;
        font-weight: 400;
        color: #fff;
        text-align: start;
        width: 100%;

    }
    .banner1 {
        width: 85% !important;
    }
    

}
