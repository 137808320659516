:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}

.OurStoryMainSec {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #F6FAFF;
    /* margin-bottom: 5%; */
    padding: 2% 0;

}

.OurStoryInnerSec {
    display: flex;
    width: 85%;
    align-items: center;
    justify-content: space-between;
}

.infosec {
    display: flex;
    width: 40%;
    flex-direction: column;
    padding-right: 2%;
}

.infosec h4 {
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    color: var(--bl);
    padding-bottom: 5%;
}

.infosec h6 {
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: var(--bl);
text-align: justify;
}

.imgsec {
    display: flex;
    align-items: center;
    width: 55%;
    justify-content: right;
}

.imgsec .imgBOX {
    width: 90%;
    display: flex;
    object-fit: contain;
    margin: 5% 0%;
}

.imgsec .imgBOX img {
    width: 100%;
    display: flex;
}

/* ------------------------------- */

@media(max-width:1250px){
    .infosec h6 {
        font-size: 12px;
    }
    .infosec h4 {
        font-size: 25px;
    
    }
}
@media(max-width:1150px) {
    .infosec {
        width: 50%;
    }

    .infosec h4 {
        font-size: 25px;
        padding-top: 5%;
    }
    .infosec h6 {
        font-size: 12px;
        padding-bottom: 5%;
    }
}

@media(max-width:825px) {
    .OurStoryMainSec {
        display: flex;
        width: 100%;
    }
    .OurStoryInnerSec {
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .infosec {
        width: 100%;
    }
    .infosec h4 {
        width: 100%;
        font-size: 20px;
        text-align: start;
    }
    .infosec h6 {
        width: 100%;
    }

    .imgsec{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;        
    }

    
}
@media(max-width:415px) {
    .OurStoryInnerSec{
        display: flex;
        flex-direction: column-reverse;
    }
    .imgsec{
        display: flex;
        width: 100% !important;
        margin-top: 5%;
    }
    .imgBOX{
        display: flex;
        width: 100% !important;
    }
    .infosec h4 {
        padding-top: 0% !important;
    }
    .infosec h6 {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }

    
}