
.USerLOginMAin {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: hidden;
}

.USerLOginStart {
    width: 100%; /* Set width to 100% for responsiveness */
    height: 100vh; /* Set height to 100% of the viewport height */
    display: flex;
    justify-content: center;
}
.USerLOginBox{
    width: 50%;
    /* background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg'); */
    display: flex;
    justify-content: center;
    align-items: center;
}
.USerLOginBox iframe {
    margin-top: 30%;
    width: 100%; /* Adjust the width as needed */
    height: 100vh; /* Adjust the height as needed */
}
.sib-form{
    padding: 0px;
}
.USerLOginBox2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg');
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat;
    background-color: #F6FAFF;
    height: 100vh;

}

.USerBoxSTart2 {
    width: 50%;
}

.USerBoxSTart2 .USerMEdiaBox2 {
    width: 100% !important;
    

}

.USerMEdiaBox2 img {
    border-radius: 2%;
    width: 100%;
    object-fit: cover ;

}

.USerBOxInfo {
    width: 100 !important;
    text-align: center;
    margin-top: 10%;
}

.USerBOxInfo h4 {
    width: 100 !important;
    color: #2B2727;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 3%;
}

.USerBOxInfo h6 {
    width: 100 !important;
    color: #606778;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.USerBoxSTart2 ul .slick-active {
    width: 40px !important;
}

.USerBoxSTart2 ul .slick-active button {
    width: 40px !important;
}

.USerBoxSTart2 .slick-dots li button:before {
    width: 15px !important;
    content: '';
    background-color: gray;
    height: 5px;
    border-radius: 6px;
}

.USerBoxSTart2 ul .slick-active button::before {
    width: 40px !important;
    content: '';
    background-color: black !important;
    height: 5px;

}

  

@media (max-width: 1024px) {
    .USerLOginBox {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-image: url('https://res.cloudinary.com/dtffniutw/image/upload/v1689830796/BG_with_circle_geiqbf.svg');
    
    }

    .USerLOginBox iframe {
        margin-top: 80%;
        width: 100%; /* Adjust the width as needed */
        height: 100vh; /* Adjust the height as needed */
    }

    .USerLOginBox2 {
        display: none;
    }
}


@media(max-width:768px) {
    .USerBOxInfo h4 {
        font-size: 20px;

    }

    .USerBOxInfo h6 {
        font-size: 17px;
    }
}

@media(max-width:620px) {
    .USerLOginBox2 {
        display: none;
    }

    .USerLOginBox {
        width: 100%;
    }
}

@media(max-width:415px) {
    .USerLOginBox{
        width: 100% !important;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }


   .USerLOginBox, .USerLOginFOrm {
        width: 100%;
        height: 100vh;
        overflow-y: hidden;
    }
    .USerLOginBox button {
        width: 100%;
    }
    .USerLOginBox h5{
        font-size: 14px;
    }
}