.NewsMain {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8%;
    margin-bottom: 8%;
}
.lineclamp {
    display: -webkit-box;
    /* -webkit-line-clamp: 3; */
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-wrap: break-word; /* Add this property to prevent long words from causing overflow */
}

  .newsContent  *{
    font-weight: 300 !important;
    font-size: 15px;
   }
.NewsStart {
    display: flex;
    width: 85%;
    justify-content: space-evenly;
}

.NewsStart a {
    width: 30%;
}

.ContentBGMain {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.ContentBG {
    width: 100%;

}
.Info p{
    text-align: justify;
}
.ContentInfo {
    width: 35%;
    display: flex;
    justify-content: center;
    margin-top: 3%;

}

.ContentInfoStart a {
    width: 100%;

}

.ContentInfoStart {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.ContentBG img {
    width: 100%;
}

.ArticleDown {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ArticleDown h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #585858;
    margin-bottom: 4%;
}

.ContentImg {
    width: 100%;
    background: #F9F9F9;
    display: flex;
    justify-content: center;
    border-radius: 4px;
}

.ContentBG h6 {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.06em;
    text-transform: capitalize;
    color: #656363;
}

.ContentBG h4 {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: -1px;
    color: #585858;
}

.ContentBG h5 {
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
    color: #6D6E76;
    text-align: justify;
}

.ContentBG h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #585858;
    margin-bottom: 4%;

}

/* ------------------- */
.ContentInfo h6 {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.06em;
    text-transform: capitalize;
    color: #656363;
    padding-bottom: 1%;
}

.ContentInfo h4 {
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -1px;
    color: #585858;
    padding-bottom: 2%;
}

.ContentInfo h5 {
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
    color: #6D6E76;
   
}

.Info {

    border-bottom: 1px solid rgba(217, 217, 217, 1);
    display: flex;
    flex-direction: column;
    width: 100%;
}

/* -------------------- */
.ArticleBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 0;
}

.ArtContentStart {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #bcbcbc5e;
    gap: 15px;
    padding-bottom: 10px;
}

.ArticleBox1 {
    width: 70%;
    display: flex;
    gap: 10px;
    flex-direction: column;
    

}

.ArticleBox1 h4 {
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: -1px;
    color: #BCBCBC;

}

.ArticleBox1 p {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #656363;
    text-align: justify;

}

.ArticleBox2 {
    background-color: #fff;
    width: 60px;
    height: 60px;
    text-align: center;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5%;
    border: none;
    margin-top: 4.3%;
    margin-bottom: 4.3%;
}

.ArticleBox2 h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
}

.ArticleBox2 h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    line-height: 17px;
    text-align: center;
}

.dateDiv
{
    flex-grow:1 ;
    height: 60px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 2px; */
    /* padding: 3px; */

}
.dateDiv p
{
font-size: clamp(10px,14px,16px);
color: black;
font-weight: 600;

}
.dateDiv span
{
font-size: clamp(10px,12px,14px);
color: black;

}

.SingleBoxCon
{
    padding: 10px;
}

/* -----------media-------------- */

@media(max-width:1150px) {
    .NewsStart {
        display: flex;
        flex-wrap: wrap;
    }

    .ArticleDown {
        width: 100%;
    }

    .ContentBGMain {
        width: 45% !important;

    }

    .ContentBG {
        width: 100%;
    }

    .ContentInfo {
        width: 50% !important;

    }

    .ArticleDown {
        margin-top: 5%;
    }

    .ArticleBox1 h4 {
        font-size: 20px;
        padding-bottom: 1%;
    }

    .ArticleBox1 p {
        font-size: 18px;

    }

    .ArticleBox2 {
        width: 100px;
        height: 100px;
    }

    .ArticleBox2 h6 {
        font-size: 20px;
        line-height: 28px;
    }

    .ContentBG h6 {
        font-size: 15px;
        padding-bottom: 2%;
    }

    .ContentBG h4 {
        padding-bottom: 2%;
        font-weight: 700;
    }

    .ContentBG h5 {
        font-size: 18px;
        line-height: 25px;
    }

    .ContentInfo h6 {
        padding-bottom: 2%;
    }

    .ContentInfo h4 {
        font-size: 20px;
    }

    .ContentInfo h5 {
        font-size: 18px;
        line-height: 20px;

    }


}

@media(max-width:912px) {
    .NewsStart {
        display: flex;
        transition: 0.6s ease-in-out;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ContentBGMain {
        width: 100% !important;

    }

    .ContentBG {
        width: 100%;
    }

    .ContentInfo {
        margin-top: 5%;
        width: 100% !important;

    }

    .ContentInfoStart {
        width: 100% !important;

    }

    .ContentBG h6 {
        width: 100%;
    }

    .ContentBG h4 {
        width: 100%;

    }

    .ContentBG h5 {
        width: 100%;

    }

    .ContentInfo h6 {
        width: 100%;
    }

    .ContentInfo h4 {
        width: 100%;
    }

    .ContentInfo h5 {
        width: 100%;
    }

    .ArticleBox2 h6 {
        font-size: 15px;

    }


    @media(max-width:768px) {
        .ContentBG h6 {
            font-size: 12px;
            padding-bottom: 1%;
        }

        .ContentBG h4 {
            font-size: 18px;
            padding-bottom: 1%;

        }

        .ContentBG h5 {
            font-size: 15px;
            line-height: 25px;

        }

        .ContentInfo h6 {
            padding-bottom: 1%;
            font-size: 12px;
        }

        .ContentInfo h4 {
            font-size: 18px;
            padding-bottom: 1%;
        }

        .ContentInfo h5 {
            font-size: 12px;
            line-height: 20px;

        }

        .ArticleBox1 h4 {
            font-size: 18px;
            padding-bottom: 1%;
        }

        .ArticleBox1 p {
            font-size: 15px;

        }

        .ArticleBox2 {
            width: 80px;
            height: 80px;
        }

        .ArticleBox2 h6 {
            font-size: 15px;
            line-height: 28px;
        }
    }


    @media(max-width:500px) {
        .ContentBG h6 {
            font-size: 10px;
        }

        .ContentBG h4 {
            font-size: 15px;
            padding-bottom: 1%;
            line-height: 20px;

        }

        .ContentBG h5 {
            font-size: 12px;
            line-height: 20px;

        }

        .ContentInfo h6 {
            font-size: 10px;
        }

        .ContentInfo h4 {
            font-size: 15px;
            padding-bottom: 1%;
            line-height: 20px;
        }

        .ContentInfo h5 {
            font-size: 12px;
            line-height: 20px;

        }

        .ArticleDown h1 {
            font-size: 20px;
        }

        .ArticleBox1 h4 {
            font-size: 15px;
            padding-bottom: 0.5%;
            width: 100%;
        }

        .ArticleBox1 p {
            font-size: 12px;

        }

        .ArticleBox2 {
            width: 60px;
            height: 60px;
        }

        .ArticleBox2 h6 {
            font-size: 12px;
            line-height: 20px;
        }
    }
}

@media(max-width:415px) {
    .ArticleBox1 h4 {
        line-height: 23px;
    }

    .ArticleBox1 {
        gap: 1px;

    }
    .ContentBG h4 {
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
    }
    .Info h4{
        font-weight: 700;
        font-size: 15px;
        line-height: 24px;
    }
    .ArticleDown h1{
        padding-top: 10%;
        padding-bottom: 5%;
    }
}


.placeholderNewsParent
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.placeholderNewsDiv
{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    flex-grow:1 ;
}

.placeholderNewsHead, .placeholderNewssubHead
{
    width: 100%;
    height: 25%;
    background-color: rgba(128, 128, 128, 0.121);
}
.placeholderNewsPara
{
    width: 100%;
   
    background-color: rgba(128, 128, 128, 0.121);
    /* height: 50%; */
}