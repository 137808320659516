.DocumentMain {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DocumentStart {
    width: 85%;
    display: flex;
    flex-direction: column;
}

.DocumentStart h6 {
    width: 85%;
    display: flex;
    margin-bottom: 4%;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    color: #333333
}

.DocumentBoxStart {
    display: flex;
    width: 100%;
    margin-bottom: 4%;

}

.DocumentBox {
    width: 22%;
}

.DocumentBox h5 {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #444444;
}


/* ------------------------------------------------- */

.DocumentStart h1 {
    width: 85%;
    display: none;
    /* display: flex; */
    margin-bottom: 3%;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    color: #333333;
    margin-top: 5%;
}
.AccordionMainSection {
    /* display: flex; */
    display: none;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.AccordionMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10%;
}


.AccordionSection {
    width: 100%;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    color: #000000;
}

.AccordionAddress {
    width: 80%;
}

.AccordionContact {
    display: flex;
    width: 50%;
    padding: 2% 0;
 
}




@media(max-width:912px) {
    .AccordionMain h5 {
        font-size: 18px;
    }

    .AccordionSection {
        font-size: 18px;
    }

    .AccordionAddress {
        font-size: 15px;
    }

    .AccordionContact {
        font-size: 15px;

    }

}

@media(max-width:820px) {

    .AccordionSection {
        font-size: 15px;
    }

    .AccordionAddress {
        font-size: 12px;
    }

    .AccordionContact {
        font-size: 12px;

    }

}

@media(max-width:540px) {
    .DocumentStart h6 {
        width: 85%;
        font-size: 20px;
        line-height: 22px;
    }
    .DocumentBox h5 {
        font-size: 12px;
        line-height: 22px;
    }
    .detailLink img{
        width: 50%;
    }

}
@media(max-width:415px) {
    .AccordionAddress {
        width: 80%;
        padding-bottom: 2%;
    }
    .DocumentStart h6 {
        width: 100%;
        font-size: 20px;
        line-height: 20px;
        margin: 4% 0;
    }
    .DocumentBox h5 {
        font-size: 12px;
        line-height: 20px;
    }
    .detailLink img{
        width: 50%;
    }
    .DocumentBox{
        margin: 3% 0;
    }
    .DocumentMain{
        margin-bottom: 15%;
    }

}

