
.Invpdfmain{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #f6faff;

}
.Brochuremain{
  display: flex;
  width: 100%;
  height: 100vh;

}


