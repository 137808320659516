.NavLinks {
    margin: 0 25px;
    text-align: center;
}
:root {
    --col: #16469d;
    --bl: #585858;
    --hov: #2458b7;
    --wt: #fff;
}
.NavItems {
    font-weight: 510;
    color: #656363;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    color: var(--col);
}
.NavItems:hover {
    color: var(--col);
}
.NavbarMain {
    display: flex;
    width: 95%;
    justify-content: space-between;
    position: relative;
}
.MainClass {
    display: flex;
    width: 25%;
}

.MainClass a {
    display: flex;
    width: 100%;
    max-width: 220px;
    justify-content: flex-start;
    align-items: center;
}
.MainClass a > img {
  
    max-width: 100%;

   
}
.navLinks
{
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 25px;
   
}
.toggleBtn
{
    display: none;
}

@media screen and (max-width: 786px) {
    .NavbarMain {
        
        width: 100%;
        padding: 0 10px;
        justify-content: center;
        position: relative;
       
    }
    .MainClass {
        display: flex;
        width: 100%;
    }
    .navLinks{
        display: none;
    }
    .MainClass > a  {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        height: 70px;
        max-width: 100%;
      
    }
    .MainClass > a > img{
       height: 70%;
    }
    .toggleBtn
    {
        display: block;
        position: absolute;
        left: 25px;
       display: flex;
       justify-content: center;
       align-items: center;
      
    }
    .toggleBtn > svg
    {
        width: 35px;
        height: 35px;
    }
    
}
.NavStart {
    display: flex;
    /* padding-left: 3%; */
    box-sizing: border-box;
    justify-content: flex-end;
    gap: 25px;
   
}

@media screen and (max-width: 1000px) {
    .NavStart {
       
        gap: 10px;
       
    }
    .navLinks{
        gap: 10px;
    }
}
.Btn {
    display: flex;
    /* width: 10%; */
}

.header {
    background-color: #ffffff;
    box-shadow: none;
}

div .mynavbar {
    background-color: transparent;
    box-shadow: none;
}

div .headerbg {
    background-color: #ffffff;
}


.bussinessDropDown
{
    position: relative;
}
.bussinessDropDownUl
{
   
    width: 170px;
    position: absolute;
    top: 100%;
    background-color: #ffffff;
    flex-direction: column;
    list-style: none;
    border-radius: 4px;
    display: none;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: all 0.3s ease-in-out;
}
.bussinessDropDownUl > li
{
padding: 10px 15px;
display: flex;
width: 100%;
justify-content: flex-start;
align-items: center;
}
.bussinessDropDownUl > li:hover
{
    background-color: rgba(25, 118, 210, 0.04);
}
.bussinessDropDownUl > li >a
{
color: #585858;
width: 100%;
text-decoration: none;
text-align: left;
}

.bussinessDropDown:hover .bussinessDropDownUl
{
    display: flex;
}
.dropdown-content {
    display: none;
    /* Add any other necessary styles to position and style the dropdown content */
  }
  
  /* Show the dropdown content when the class is active */
  .show {
    display: block;
  }
@media (max-width: 940px) {
    .NavbarMain {
        width: 100%;
    }
}
@media (max-width: 600px) {
    .NavStart {
        display: none;
    }
    .Btn {
        display: none;
    }
    /* .MainClass {
        display: flex;
        width: 40%;
        justify-items: flex-end;
    } */
}

@media (max-width: 795px) {
    /* .MainClass img {
        display: flex;
        width: 80%;
    } */
    .Btn {
        line-height: 2px;
        font-size: 12px;
    }
}
@media (max-width: 415px) {
 /* .MainClass img{
    width: 100%;
   
 } */
}
