.InvestOurMain {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 0;
}

.InvestOurStart {
    width: 85%;
    display: flex;
    flex-direction: column;
}

.TeamBox1 {
    width: 60%;
    margin: 2% 0;
}

.TeamBox1 h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 26px;
    color: #585858;
    padding-bottom: 4%;
}

.TeamBox1 h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: #585858;
}

/* ------------------------------------ */
:root {
    --col: #16469D;
    ;
}

.highlight_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.highlight_content {
    width: 85%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.highlight_boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.highlight_box {
    padding: 1%;
    /* border: 0.5px solid #dfdfdf; */
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 30%;
    /* max-height: 600px; */
    min-height: 400px;
    margin-top: 2%;
}


.highlight_boxStart2 h6 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #333333
}




.invest_opp {
    width: 85%;
    display: flex;
    align-items: center;
    margin-bottom: 5%;
}

.invest_title {
    font-size: 20px;
    font-weight: 500;
    color: white;
    background-color: var(--col);
    display: flex;
    align-items: center;
    padding-left: 5%;
    height: 83px;
    width: 80%;
    border-radius: 4px 0px 0px 4px;
}

.invest_button {
    font-size: 20px;
    background-color: #F6FAFF;
    display: flex;
    align-items: center;
    width: 20%;
    padding-left: 5%;
    height: 83px;
    color: #16469D;
    border-radius: 0px 4px 4px 0px;
}


.highlight_boxStart {
    width: 100%;
    display: flex;
    gap: 15px;
    margin-bottom: 4%;
}

.highlight_boxCircle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #B43E35;

}


.highlight_boxCircle h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 105px;
    color: #fff;
}

.highlight_boxName {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.highlight_boxStart h2 {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-transform: capitalize;
    color: #253142;
    padding: 1% 0;

}

.highlight_boxStart h6 {
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #4F5766;
    text-align: left;
    width: 100%;

}

@media(max-width: 1300px) {
    .highlight_boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    .highlight_box {
        width: 40%;

    }

}

@media(max-width: 1280px) {
    .highlight_box {
        width: 42%;
        padding: 2%;

    }

    .highlight_heading {
        font-size: 18px;
    }

    .highlight_desc {
        font-size: 14px;
    }

    .highlight_content h1 {
        font-size: 28px;
        margin-bottom: 2%;

    }

    .invest_button {
        width: 20%;
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .invest_button h6 {
        font-size: 20px;
    }
}

@media(max-width: 1024px) {

    .highlight_heading {
        font-size: 16px;
    }

    .highlight_desc {
        font-size: 12px;
    }

    .highlight_content h1 {
        font-size: 25px;
    }

    .invest_title {
        font-size: 20px;
        padding-left: 5%;
        height: 60px;
        width: 80%;
    }

    .invest_button {
        width: 20%;
        height: 60px;
    }

    .invest_button h6 {
        font-size: 20px;
    }

    .TeamBox1 h2 {
        font-size: 28px;
        line-height: 25px;
    }

    .TeamBox1 h6 {
        font-size: 14px;
        line-height: 22px;

    }
}

@media(max-width: 912px) {
    .invest_title {
        font-size: 20px;
        padding-left: 4%;
        height: 50px;
        width: 70%;
    }

    .invest_button {
        width: 30%;
        height: 50px;
    }

    .invest_button h6 {
        font-size: 18px;
    }

    .TeamBox1 h2 {
        font-size: 26px;
        line-height: 25px;
    }


}

@media(max-width: 820px) {
    .highlight_box {
        width: 45%;
    }

    .highlight_heading {
        font-size: 16px;
    }

    .highlight_desc {
        font-size: 12px;
    }

    .highlight_content h1 {
        font-size: 22px;
    }

    .invest_title {
        font-size: 18px;
        padding-left: 4%;
        height: 50px;
    }

    .invest_button {
        height: 50px;
    }

    .invest_button h6 {
        font-size: 18px;
        display: flex;
        justify-content: center;

    }

    .TeamBox1 h2 {
        font-size: 25px;
        line-height: 24px;
        width: 100% !important;

    }

}

@media(max-width: 768px) {
    .highlight_box {
        display: flex;
        flex-direction: column;
        width: 70%;
        padding: 4%;
    }

    .highlight_heading {
        font-size: 15px;
    }

    .highlight_desc {
        font-size: 12px;
        width: 100%;
    }

    .highlight_content h1 {
        width: 100%;
        text-align: center;
        font-size: 20px;

    }

    .invest_title {
        font-size: 15px;
        width: 100%;
        padding-left: 4%;
        height: 40px;
        width: 70%
    }

    .highlight_boxStart2 h6 {
        width: 100%;
    }

    .invest_button {
        height: 40px;
        width: 30%;
        padding: 0 !important;

    }

    .invest_button h6 {
        font-size: 12px;

    }

    .TeamBox1 h2 {
        font-size: 24px;
        line-height: 20px;
        width: 100% !important;
    }

    .TeamBox1 h6 {
        font-size: 13px;
        line-height: 20px;
        width: 100% !important;

    }

    .TeamBox1 {
        width: 80%;
    }

    .highlight_boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .highlight_boxCircle h1 {
        font-size: 13px;
        line-height: 90px;
    }
    
    .highlight_boxStart h2 {
        font-size: 15px;
        line-height: 20px;
    }
    
    .highlight_boxStart h6 {
        font-size: 12px;
        line-height: 20px;
    
    }
    
}

@media(max-width:540px) {
    .highlight_boxes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .highlight_box {
        min-height: 320px;
        margin: 2% 0;

    }
    
    .highlight_box {
        width: 90%;
    }

    .invest_title {
        font-size: 15px;
        padding-left: 4%;
        height: 35px;
        width: 70%
    }

    .invest_button {
        height: 35px;
        width: 30%;
        padding: 0 !important;

    }

    .invest_button h6 {
        font-size: 12px;

    }

    .TeamBox1 h2 {
        font-size: 22px;
        line-height: 20px;
    }

    .TeamBox1 h6 {
        font-size: 12px;
        line-height: 20px;

    }

    .TeamBox1 {
        width: 100%;
    }
    .highlight_boxCircle h1 {
        font-size: 15px;
        line-height: 80px;
    }
    
    .highlight_boxStart h2 {
        font-size: 15px;
        line-height: 20px;
    }
    
    .highlight_boxStart h6 {
        font-size: 12px;
        line-height: 20px;
    
    }
    .highlight_boxCircle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    
    }
    
}

@media(max-width:456px) {
    .invest_title {
        font-size: 12px;
        padding-left: 2%;
        height: 30px;

    }

    .invest_button {
        height: 30px;
        padding: 0 !important;

    }

    .invest_button h6 {
        font-size: 10px;

    }
}

@media(max-width:415px) {
    .highlight_content h1 {
        display: none;
    }

    .highlight_box {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 2%;
        border: none;
        min-height: 320px;
        margin: 2% 0;
    }

    .invest_title {
        width: 60%;
        height: 40px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .invest_button {
        width: 40%;
        height: 40px;
        padding: 0 !important;

    }

    .invest_button h6 {
        font-size: 12px;

    }

    .invest_opp {
        margin-top: 10%;
    }

    .TeamBox1 h2 {
        font-size: 20px;
        line-height: 20px;
    }

    .TeamBox1 h6 {
        font-size: 12px;
        line-height: 20px;

    }
    .highlight_box{
        margin: 10% 0;
    }
}



