.bannerStart{
    background:linear-gradient(123.13deg, rgba(0, 0, 0, 0.479) 25.08%, rgba(0, 0, 0, 0.008) 104.94%), url('https://res.cloudinary.com/dxluokypg/image/upload/v1681550750/Join_us_2_eclrgq.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40vh;
    margin-bottom: 4%;
}
.banner1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 85% !important;
}
.banner1 h2{
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media(max-width:1280px){
    .bannerStart{
        height: 38vh;
        margin-bottom: 4%;
    }

    .banner1 h2{
        font-weight: 500;
        font-size: 35px;
        line-height: 43px;
    }
    
}
@media(max-width:1024px){
    .bannerStart{
        height: 35vh;
        margin-bottom: 4%;
    }

    .banner1 h2{
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
    }
    
}
@media(max-width:820px){
    .bannerStart{
        height: 30vh;
        margin-bottom: 4%;
    }

    .banner1 h2{
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
    }
}
@media(max-width:540px){
    .bannerStart{
        height: 26vh;
        margin-bottom: 4%;
    }

    .banner1 h2{
        font-weight: 500;
        font-size: 25px;
        line-height: 40px;
    }
    
}
@media(max-width:416px){
    .bannerStart{
        height: 25vh;
        margin-bottom: 4%;
    }

    .banner1 h2{
        font-weight: 500;
        font-size: 20px;
        line-height: 40px;
    }
    
}