:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    --wt: #fff;
}
.JabDescBgMain {
    background:var(--col);
    height: 30vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.JabDescBgStart {
    width: 85%;
    display: flex;
    flex-direction: column;

}

.JabDescBgStart h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: var(--wt);
    padding-bottom: 0.5%;
}

.JabDescBgStart h6 {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
padding-bottom: 1%;
    color: var(--wt);

}

.JabDescBgStart button {
    width: 12%;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    background-color: var(--wt);
    color: var(--col);
}
.JabDescBgStart button:hover{

    color: var(--wt);
    background-color: var(--hov);
}

@media(max-width:1280px){
    .JabDescBgStart h3 {
        font-size: 28px;
    }
    
    .JabDescBgStart h6 {
        font-size: 14px;
    }
    .JabDescBgStart button {
        width: 18%;
    }
}
@media(max-width:820px){
    .JabDescBgStart h3 {
        font-size: 25px;
        line-height: 30px;
    }
    
    .JabDescBgStart h6 {
        font-size: 12px;
    }
    .JabDescBgStart button {
        width: 15%;
        font-size: 10px;
        padding: 0;
    }
}

/* -------------phone view---------- */
@media(max-width:415px){
    .JabDescBgMain{
        height: 25vh !important;
        margin-bottom: 15%;
    
    }
    .JabDescBgStart h3 {
        font-size: 25px;
        line-height: 30px;
        color: #fff;

    }
    .JabDescBgStart h6 {
        color: #fff;
        font-size: 12px;
        padding-bottom: 2%;
    }
    .JabDescBgStart button {
        width: 40%;
        font-size: 12px;
        padding: 1% 0;
        background-color:  #fff;
        color: #16469d;
    }
    .JabDescBgStart button:hover{
        background-color: #2458B7;
        color: #fff;
    }
}