.ContactUsForm {
    display: flex;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    width: 100%;
}

.ContactCard {
    box-shadow: none;
    width: 80%;
}

.ContactCardContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 3%;
    ;
}

.ContactFormTextField {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

}

.ContactFormTextField .ContInput {
    height: 45px;
}
.ContactFormTextField .NumberArea {
    height: 45px;
}

.ContactFormTextField textarea {
    width: 100%;
    border: 1px solid rgb(176, 175, 175);
    padding: 10px 0 0 10px;
    color: #585858;
    resize: none;
    font-weight: 300;
    font-family: Inter;
    font-size: 17px;
    line-height: 28px;
    border-radius: 5px;
    outline: none;
}

.ContactFormTextField textarea:hover {
    border: 1px solid #16469D;
    /* outline:2px solid #16469D; */
}

.ContactFormTextField textarea:focus {

    outline: 2px solid #16469D;

}

.ContactFormTextField input {
    width: 100%;
    /* margin-bottom: 20px; */
    color: #585858;
}

.ContactFormTextField input:hover {
    border: 1px solid #16469D;

}

.ContactFormTextField input:focus {
    outline: 1px solid #16469D;
}

.SubmitBtn {
    width: 100%;
    margin-top: 7%;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #fff;
    display: flex;
    padding: 1.5% 0;
    cursor: pointer;
    background-color: #2458B7;

}

.textareaInp::placeholder {
    font-size: 15px;
    color: #585858;
}

.errorsContactus {
    font-size: 12px;
    color: red;
    text-align: start;
}




@media(max-width:912px) {


    .ContInput label {
        font-size: 15px !important;
    }

    .NumberArea label {
        font-size: 15px !important;

    }

    .textareaInp {
        font-size: 15px !important;

    }

    .SubmitBtn {
        font-size: 15px !important;
        padding: 1%;

    }

}

@media(max-width:540px) {

    .ContInput label {
        font-size: 12px !important;
    }

    .NumberArea label {
        font-size: 12px !important;

    }

    .textareaInp {
        font-size: 15px !important;

    }

    .textareaInp::placeholder {
        font-size: 12px !important;

    }

    .SubmitBtn {
        font-size: 12px !important;

    }

    .ContactUsFormStart {
        display: flex !important;
        width: 100% !important;
    }

    .ContactCard {
        width: 100% !important;
    }
}

@media(max-width:415px) {
    .ContactUsFormStart {
        display: flex !important;
        width: 100% !important;
        flex-direction: column-reverse !important;
    }

    .ContactCard {
        width: 100%;
    }

    .ContactUsFormStart h5 {
        padding-bottom: 2%;
    }

    .ContInput label {
        padding-top: 1%;
    }

    .NumberArea label {
        padding-top: 1%;
    }
}