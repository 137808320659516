
    .privacypolmain{
        width: 100%;
        display: flex;
        justify-content: center;
        margin:5% 0;
    }
    .privacypolStart{
        width: 85%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    
    }
    .privacyHeader{
        width: 100%;
        border-bottom: 2px solid #585858;
    }
    .privacyBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
    .privacyHeader h2{
        font-weight: 700;
        font-size: 30px;
        line-height: 58px;
        color: #585858
    }
    .privacyHeader h3{
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #16469d;
    }
    .privacyHeader h4{
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #585858;
        padding-bottom: 1%;
    }
    .privacypolcontent{
        width: 100%;
        margin-top: 1%;
        color: #585858;
    }
    
    
    @media(max-width:540px){
        .privacyHeader h2{
            font-weight: 700;
            font-size: 25px;
            line-height: 55px;
            color: #585858
        }
        .privacyHeader h3{
            font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: #16469d;
        }
        .privacyHeader h4{
            font-weight: 500;
            font-size: 15px;
            line-height: 15px;
            color: #585858;
            padding-bottom: 1%;
        }
        .privacypolcontent{
            font-size: 15px;
        }
    
        
    }
    @media(max-width:415px){
        .privacyHeader h2{
            font-weight: 700;
            font-size: 20px;
            line-height: 50px;
            color: #585858
        }
        .privacyHeader h3{
            font-weight: 400;
            font-size: 15px;
            line-height: 28px;
            color: #16469d;
        }
        .privacyHeader h4{
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            color: #585858;
            padding-bottom: 1%;
        }
        .privacypolcontent{
            font-size: 12px;
    
        }
    }