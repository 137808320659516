:root {
    --col: #16469D;
    --bl: #585858;
    --hov: #2458B7;
    /* --wt: #fff; */
}

.AboutUsPageNewletterMain {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #F6FAFF;
    /* opacity: 0.8; */
    justify-content: center;
}

.AboutUsPageNewletter {
    display: flex;
    width: 75%;
    justify-content: space-between;

}

.AboutUsPageNewletterBox1 {
    display: flex;
    flex-direction: column;
    width: 48%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.AboutUsPageNewletterBox1 h4 {
    font-weight: 510;
    font-size: 30px;
    line-height: 36px;
    color: var(--bl);
    padding-bottom: 2%;
}

.AboutUsPageNewletterBox1 h6 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px !important;
    color: var(--bl);
}

.AboutUsPageNewletterBox2 {
    display: flex;
    flex-direction: column;
    width: 48%;
    /* align-items: center; */
    margin-top: 5%;
    margin-bottom: 5%;
}

.AboutUsPageNewletterBox2 {
    display: flex;
    width: 10%;
    height: 65px;
    border: none;
    /* background-color: var(--wt); */
    border: 1px solid --col;
}

/* .BtnStart input:focus {

    outline: 1px solid #16469D;
} */

/* .BtnStart input {
    width: 70%;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--bl);
    background: transparent;
    border: 1px solid #16469D;

    text-indent: 2%;
} */


.BtnStart Button {
    width: 10%;
    /* border-radius: 1%; */
    background-color: var(--col);
    color: var(--wt);
}

.BtnStart Button:hover {
    background-color: var(--hov);
}


/* // ----------------------------media------------------------------------------- */
@media(max-width:1280px) {
    .AboutUsPageNewletter {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .AboutUsPageNewletterBox1 {
        width: 45%;
    }

    .AboutUsPageNewletterBox2 {
        display: flex;
        justify-content: flex-end;
        width: 45%;
    }

    .AboutUsPageNewletterBox2 .BtnStart {
        display: flex;
        width: 100%;
    }

    .AboutUsPageNewletterBox1 h4 {
        font-size: 27px;
    }

    .AboutUsPageNewletterBox1 h6 {
        font-size: 17px;
    }

    .BtnStart input {
        width: 70%;
        font-size: 15px;
    }

    .BtnStart Button {
        width: 30%;
        font-size: 15px;

    }
}

@media(max-width:1024px) {
    .AboutUsPageNewletterBox2 .BtnStart {
        display: flex;
        width: 90%;
        height: 55px;
    }

}

@media(max-width:900px) {
    .AboutUsPageNewletter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .AboutUsPageNewletterBox1 {
        width: 80%;
    }

    .AboutUsPageNewletterBox2 {
        display: flex;
        width: 80%;
    }

    .AboutUsPageNewletterBox1 h4 {
        font-size: 22px;
    }

    .AboutUsPageNewletterBox1 h6 {
        font-size: 17px;
    }

    .BtnStart input {
        font-size: 15px;
    }

    .BtnStart Button {
        font-size: 12px;
    }
}

@media(max-width:821px) {
    .AboutUsPageNewletterBox1 {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin-bottom: 2%;
    }
}

@media(max-width:530px) {
    .AboutUsPageNewletterBox1 h4 {
        font-size: 20px;
        width: 100%;
    }

    .AboutUsPageNewletterBox1 h6 {
        font-size: 15px;
        width: 100%;
    }

    .BtnStart input {
        font-size: 12px;
    }

    .BtnStart Button {
        font-size: 10px;
    }
    .AboutUsPageNewletterBox2 .BtnStart {
        display: flex;
        width: 100%;
        height: 40px;
    }
}
@media(max-width:415px) {
    .btnbtn > button{
        width: 2%;
        background-color: red;
    } 
    
    .AboutUsPageNewletterBox1 h4 {
        font-size: 20px;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
    }
    

    .AboutUsPageNewletterBox1 h6 {
        font-size: 12px;
        padding-left: 0 !important;
    }

    .BtnStart input {
        font-size: 12px;
        text-indent: 10px;
    }

    
    .AboutUsPageNewletterBox2 .BtnStart {
        display: flex;
        width: 100%;
        height: 45px;
        margin-bottom: 5%;
    }
    .AboutUsPageNewletter {
        width: 100%;
        text-align: center;
    
    }
    .AboutUsPageNewletterBox1 {

        margin-bottom: 0% !important;
    }
    

}